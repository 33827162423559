import Vue from "vue";
import VueRouter from "vue-router";
import messages from "@/i18n";
import store from "../store";
import { PERMISSIONS } from "@/domain_constants";
import { MODULES } from "@/app_constants";
//Layout
const HomeView = () => import(/* webpackChunkName: "home" */ '../components/views/HomeView.vue');
const LoginView = () => import(/* webpackChunkName: "login" */ '../components/views/LoginView.vue');
const PasswordChangeView = () => import(/* webpackChunkName: "change_pass" */ '@/components/views/PasswordChangeView')
const NotFoundView = () => import(/* webpackChunkName: "not_found" */ '../components/views/NotFoundView.vue');
const LayoutComponent = () => import(/* webpackChunkName: "layout" */ '../components/shared/LayoutComponent.vue');
const BodyComponent = () => import(/* webpackChunkName: "body" */ '../components/shared/BodyComponent.vue');

//UserControl
const CollaboratorInfoView = () => import(/* webpackChunkName: "collaborator" */ '../components/views/user-control/CollaboratorInfoView.vue')

//CreateProcess
const CalendarTimelineComponent = () => import(/* webpackChunkName: "calendar" */ '../components/views/timeline/CalendarTimelineComponent.vue');

//Process
const ProcessWrap = () => import(/* webpackChunkName: "process_container" */ '../components/views/process/ProcessWrap.vue')
const ProcessListView = () => import(/* webpackChunkName: "process_list" */ '../components/views/process/ProcessListView.vue')
const ProcessDetailCreateView = () => import(/* webpackChunkName: "process_detail" */ '../components/views/process/ProcessDetailCreateView.vue')

//Activity
const ActivityWrap = () =>
	import(/* webpackChunkName: "activity_container" */  "../components/views/activity/ActivityWrap.vue");
const ActivityFilterView = () =>
	import(/* webpackChunkName: "activity_filter" */  "../components/views/activity/ActivityFilterView.vue");

//Muniment
const MunimentManageView = () => import(/* webpackChunkName: "muniment" */ '../components/views/muniment/MunimentManageView.vue')
const MunimentProcessHistoryView = () => import(/* webpackChunkName: "muniment" */ '../components/views/muniment/MunimentProcessHistoryView.vue')

//Exogenous
const ExogenousWrap = () => import(/* webpackChunkName: "exogenous_container" */ '../components/shared/exogenous/ExogenousWrap.vue')
const ExogenousComponent = () => import(/* webpackChunkName: "exogenous" */ '../components/shared/exogenous/ExogenousComponent.vue')
const ExogenousGraph = () => import(/* webpackChunkName: "exogenous_graph" */ '../components/shared/exogenous/ExogenousGraph.vue')

//Timeline
const ConfigurationTimelineView = () =>
	import(/* webpackChunkName: "timeline_config" */ "../components/views/timeline/ConfigurationTimelineView.vue")
const TimelineCustomization = () => import(/* webpackChunkName: "timeline_edit" */ "../components/views/timeline/TimelineCustomizationView.vue")

const AddActivityView = () => import(/* webpackChunkName: "activity_create" */ '../components/views/activity/AddActivityView.vue');

//User profile
const UserProfileView = () => import(/* webpackChunkName: "profile" */ '../components/views/user/UserProfileView.vue');
//Weighing

const ConfigurationMatrixView = () => import(/* webpackChunkName: "matrix_config" */ '../components/views/weighing/ConfigurationMatrixView.vue')
const CustomizationMatrixView = () => import(/* webpackChunkName: "matrix_edit" */ '../components/views/weighing/CustomizationMatrixView.vue')
const WeighingView = () => import(/* webpackChunkName: "weighing" */ '../components/views/weighing/WeighingView.vue')

//Instruments
const InstrumentsWrap = () => import(/* webpackChunkName: "instrument_container" */ '../components/views/instruments/InstrumentsWrap.vue')
const InstrumentsListView = () => import(/* webpackChunkName: "instrument_list" */ '../components/views/instruments/InstrumentListView.vue')
const InstrumentsView = () => import(/* webpackChunkName: "instrument" */ '../components/views/instruments/InstrumentsView.vue')
const CreateInstrumentsSectionComponent = () => import(/* webpackChunkName: "instrument_section" */ '../components/views/instruments/CreateInstrumentSectionComponent.vue')
const InstrumentAnswerView = () => import(/* webpackChunkName: "instrument_answer" */ '../components/views/instruments/InstrumentAnswerView.vue')
const InstrumentPreviewView = () => import(/* webpackChunkName: "instument_preview" */ '../components/views/instruments/InstrumentPreviewView.vue')
const TemplateInstrumentsListView = () => import(/* webpackChunkName: "instrument_template" */ '../components/views/instruments/TemplateInstrumentsListView.vue')

const CreateSectionQuestionComponent = () => import(/* webpackChunkName: "instrument_question" */ '../components/views/instruments/CreateSectionQuestionComponent.vue')
const QuestionsComponent = () => import(/* webpackChunkName: "instrument_question_c" */ '../components/shared/instrument/QuestionsComponent.vue')

const InstrumentObjectiveGroup = () => import(/* webpackChunkName: "instrument_objective" */ '../components/views/instruments/InstrumentObjectiveGroup.vue')

//Evidence and Valoration
const EvidenceView = () => import(/* webpackChunkName: "evidence" */ '../components/views/evidence-matrix/EvidenceView.vue')
const ValorationView = () => import(/* webpackChunkName: "valoratiobn" */ '../components/views/evidence-matrix/ValorationView.vue')

//Final Review
const FinalReviewMatrixView = () => import(/* webpackChunkName: "review" */ '../components/views/review/FinalReviewMatrixView.vue')
const FinalReviewTeacherEvaluation = () => import(/* webpackChunkName: "review" */ '../components/views/review/FinalReviewTeacherEvaluation.vue')
//Report
const ReportView = () => import(/* webpackChunkName: "report" */ '../components/views/report/ReportView.vue')

//Dashboard 
const DashboardWrap = () => import(/* webpackChunkName: "mtx_dashboard" */ '../components/views/dashboard/DashboardWrap.vue')
const DashboardQuickSightView = () => import(/* webpackChunkName: "mtx_dashboard" */ '../components/views/dashboard/DashboardQuickSightView.vue')
const MatrixDashboardView = () => import(/* webpackChunkName: "mtx_dashboard" */ '../components/views/dashboard/MatrixDashboardView.vue')
const DashboardTimeline = () => import(/* webpackChunkName: "mtx_dashboard" */ '../components/views/dashboard/DashboardTimeline.vue')

//Test
const TestComponentsView = () =>
	import( /* webpackChunkName: "test" */ "../components/views/TestComponentsView");

//SMTP Config
const SMTPConfigView = () => import(/* webpackChunkName: "smtp_config" */ "../components/views/smtp/SMTPConfigView.vue")

//Improvement PLan

const ImprovementPlanConfigView = () => import( /* webpackChunkName: "improvement_plan" */ '../components/views/improvement-plan/ImprovementPlanConfigView.vue')
const ActivityIPFilterView = () => import( /* webpackChunkName: "activity_ip_view"*/ '../components/views/improvement-plan/ActivityIPFilterView.vue')

//Contract
const ContractWrap = () => import(/* webpackChunkName: "contract_container" */ '../components/views/contract/ContractWrap.vue')
const ContractView = () => import(/* webpackChunkName: "contract_info" */ '../components/views/contract/ContractView.vue')
const AddContractStandard = () => import(/* webpackChunkName: "add_contract_standard" */ '../components/views/contract/AddContractStandard.vue')

// Templates
const TemplatesView = () => import(/* webpackChunkName: "activity_ip_view"*/ '../components/views/templates/TemplatesView.vue')
const TemplatesEvaView = () => import(/* webpackChunkName: "activity_ip_view"*/ '../components/views/templates/TemplatesEvaView.vue')
const TemplatesSnies2View = () => import(/* webpackChunkName: "activity_ip_view"*/ '../components/views/templates/TemplatesSnies2View.vue')

//Rubric
const RubricListView = () => import(/* webpackChunkName: "rubric_list" */ '../components/views/RubricListView.vue') 
const RubricView = () => import(/* webpackChunkName: "rubric" */ '../components/views/RubricView.vue')

//Notas Evaluacion par
const NotesListComponent = () => import(/* webpackChunkName: "notes-list" */ '../components/views/notes/NotesListComponent.vue')
const MyNotesComponent = () => import(/* webpackChunkName: "my-notes" */ '../components/views/notes/MyNotesComponent.vue')

// #Cambio del método push genérico para interceptar errores posibles
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);
let userLang = 'es'//navigator.language.substring(0, 2) || navigator.userLanguage.substring(0, 1);
function guardMyroute(to, from, next) {
	let history = window.history.length > 2
	let isAuthenticated = store.getters.isAuthenticated
	let currentUser = store.getters.getPermissions
	if (isAuthenticated && currentUser) {
		if (currentUser.routers.includes(to.name) || to.name == 'home' || to.name == 'settings-profile') { // * Si no esta en la lista de rutas con acceso o en rutas genéricas como Home y el Perfil no lo dejara entrar
			next(); // allow to enter route
		}
		else {
			Vue.$toast.warning('No tiene acceso a esta ruta, contacte un administrador');
			next({ name: 'home' });
		}
	}
	else if(isAuthenticated && currentUser == null){
		if (to.meta.module == MODULES.WEIGHING || to.meta.module == MODULES.INSTRUMENT || to.meta.module == MODULES.EVIDENCE && to.params.ext_token != undefined) {

			next();
		}
		else next();
	} else {
		if (to.meta.module == MODULES.WEIGHING || to.meta.module == MODULES.INSTRUMENT || to.meta.module == MODULES.EVIDENCE && to.params.ext_token != undefined) {

			next();
		}
		else next({ name: 'login' }); // go to '/login';
	}
}

function alreadyIsLogged(to, from, next) {
	let isAuthenticated = store.getters.isAuthenticated
	if (!isAuthenticated) {
		if (to.meta.module == MODULES.WEIGHING || to.meta.module == MODULES.INSTRUMENT || to.meta.module == MODULES.EVIDENCE && to.params.ext_token != undefined) {
			next();
		}
		else next(); // allow to enter route
	} else {
		next({ name: 'home' }); // go to '/home';
	}
}

const routes = [
	{
		path: "/",
		component: LayoutComponent,
		children: [
			{
				path: "",
				component: BodyComponent,
				children: [
					{
						path: "",
						name: "home",
						component: HomeView,
						beforeEnter: guardMyroute,
						meta: {
							title:
								messages[userLang].home && messages[userLang].home.title_h
									? messages[userLang].home.title_h
									: "PREST",
							module: MODULES.HOME,
							dbModule: MODULES.HOME,
							permissions: [PERMISSIONS.CONSULT]
						},
					},
					{
						path: "/test-components",
						name: "test-components",
						component: TestComponentsView,
						beforeEnter: guardMyroute,
						meta: {
							title:
								messages[userLang].test_components &&
									messages[userLang].test_components.title_h
									? messages[userLang].test_components.title_h
									: "PREST",
							module: MODULES.SERVER,
							dbModule: MODULES.SERVER,
							permissions: [PERMISSIONS.CONSULT]
						},
					},


					{
						path: '/calendar',
						name: 'calendar',
						component: CalendarTimelineComponent,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].test_components && messages[userLang].test_components.title ? messages[userLang].test_components.title : 'PREST',
							module: MODULES.CALENDAR,
							dbModule: MODULES.CALENDAR,
							permissions: [PERMISSIONS.CONSULT]
						}
					},
					{
						path: '/config-timeline',
						name: 'config-timeline',
						component: ConfigurationTimelineView,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].timeline.configuration && messages[userLang].timeline.configuration.title ? messages[userLang].timeline.configuration.title : 'PREST',
							module: MODULES.PROJECTS,
							dbModule: MODULES.PROJECTS,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},
					{
						path: '/dash-timeline',
						name: 'dash-timeline',
						component: DashboardTimeline,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].timeline.timeline_dashboard && messages[userLang].timeline.timeline_dashboard.title ? messages[userLang].timeline.configuration.title : 'PREST', //???????????????????? Configurado en i18n
							module: MODULES.PROJECTS,
							dbModule: MODULES.PROJECTS,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},
					{
						path: '/reg-collaborator',
						name: 'reg-collaborator',
						component: CollaboratorInfoView,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].test_components && messages[userLang].test_components.title ? messages[userLang].test_components.title : 'PREST',
							module: MODULES.PROJECTS,
							dbModule: MODULES.PROJECTS,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},

					{
						path: '/matrix',
						name: 'matrix',
						component: ConfigurationMatrixView,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].matrix && messages[userLang].matrix.title ? messages[userLang].matrix.title : 'PREST',
							module: MODULES.MATRIX,
							dbModule: MODULES.MATRIX,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},
					{
						path: '/matrix-dashboard',
						name: 'matrix-dashboard',
						component: MatrixDashboardView,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].matrix && messages[userLang].matrix.dashboard.title ? messages[userLang].matrix.dashboard.title : 'PREST',
							module: MODULES.MATRIX,
							dbModule: MODULES.MATRIX,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},
					{
						path: '/weighing/:ext_token?',
						name: 'weighing',
						component: WeighingView,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].matrix && messages[userLang].matrix.title ? messages[userLang].matrix.title : 'PREST',
							module: MODULES.WEIGHING,
							dbModule: MODULES.MATRIX,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},
					{
						path: '/evidence/:ext_token?',
						name: 'evidence',
						component: EvidenceView,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].evidence && messages[userLang].evidence.title ? messages[userLang].evidence.title : 'PREST',
							module: MODULES.EVIDENCE,
							dbModule: MODULES.MATRIX,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},
					{
						path: '/valoration/:ext_token?',
						name: 'valoration',
						component: ValorationView,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].evidence && messages[userLang].evidence.title ? messages[userLang].evidence.title : 'PREST',
							module: MODULES.EVIDENCE,
							dbModule: MODULES.MATRIX,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},
					{
						path: "/activity",
						component: ActivityWrap,
						children: [
							{
								path: "",
								name: "activity-home",
								component: ActivityFilterView,
								beforeEnter: guardMyroute,
								meta: {
									title:
										messages[userLang].activity &&
											messages[userLang].activity.title_h
											? messages[userLang].activity.title_h
											: "PREST",
									module: MODULES.PROJECTS,
									dbModule: MODULES.PROJECTS,
									permissions: [PERMISSIONS.CONSULT]
								},
							},
							{
								path: "new-activity",
								name: "new-activity",
								component: AddActivityView,
								beforeEnter: guardMyroute,
								meta: {
									title:
										messages[userLang].activity &&
											messages[userLang].activity.title_h
											? messages[userLang].activity.title_h
											: "PREST",
									module: MODULES.PROJECTS,
									dbModule: MODULES.PROJECTS,
									permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
								},
							}
						],
					},
					{
						path: "/project",
						component: ProcessWrap,
						children: [
							{
								path: "",
								name: "project-list",
								component: ProcessListView,
								beforeEnter: guardMyroute,
								meta: {
									title:
										messages[userLang].process &&
											messages[userLang].process.title_h
											? messages[userLang].process.title_h
											: "PREST",
									module: MODULES.PROJECTS,
									dbModule: MODULES.PROJECTS,
									permissions: [PERMISSIONS.CONSULT]
								},
							},
							{
								path: "detail/:process?",
								name: "project-detail",
								component: ProcessDetailCreateView,
								beforeEnter: guardMyroute,
								meta: {
									title: "PREST",
									module: MODULES.PROJECTS,
									dbModule: MODULES.PROJECTS,
									permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
								},
							},
						],
					},
					{
						path: "/settings-profile",
						name: "settings-profile",
						component: UserProfileView,
						beforeEnter: guardMyroute,
						meta: {
							title: "PREST",
							module: MODULES.PROJECTS,
							dbModule: MODULES.PROJECTS,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						},
					},

					{
						path: "/muniment",
						name: "muniment",
						component: MunimentManageView,
						beforeEnter: guardMyroute,
						meta: {
							title:
								messages[userLang].muniment &&
									messages[userLang].muniment.title_h
									? messages[userLang].muniment.title_h
									: "PREST",
							module: MODULES.PROJECTS,
							dbModule: MODULES.PROJECTS,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						},
					},
					{
						path: "/process-history-muniment",
						name: "process-history-muniment",
						component: MunimentProcessHistoryView,
						beforeEnter: guardMyroute,
						meta: {
							title:
								messages[userLang].muniment_history_process &&
									messages[userLang].muniment_history_process.title_h
									? messages[userLang].muniment_history_process.title_h
									: "PREST",
							module: MODULES.PROJECTS,
							dbModule: MODULES.PROJECTS,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						},
					},
					{
						path: "/exogenous",
						component: ExogenousWrap,

						children: [
							{
								path: "",
								name: "exogenous",
								component: ExogenousComponent,
								beforeEnter: guardMyroute,
								meta: {
									title:
										messages[userLang].exogenous &&
											messages[userLang].exogenous.title_h
											? messages[userLang].exogenous.title_h
											: "PREST",
									module: MODULES.PROJECTS,
									dbModule: MODULES.PROJECTS,
									permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
								},
							},
							{
								path: "graph/:source?/:codeProgram?",
								name: "exogeous-graph",
								component: ExogenousGraph,
								beforeEnter: guardMyroute,
								meta: {
									title:
										messages[userLang].exogenous &&
											messages[userLang].exogenous.graph.title_h
											? messages[userLang].exogenous.graph.title_h
											: "PREST",
									module: MODULES.PROJECTS,
									dbModule: MODULES.PROJECTS,
									permissions: [PERMISSIONS.CONSULT]
								},
							},
						]
					},
					{
						path: "/instrument",
						component: InstrumentsWrap,
						children: [
							{
								path: "",
								name: "instrument-list",
								component: InstrumentsListView,
								beforeEnter: guardMyroute,
								meta: {
									title:
										messages[userLang].instrument &&
											messages[userLang].instrument.create.title_h
											? messages[userLang].instrument.create.title_h
											: "PREST",
									dbModule: MODULES.INSTRUMENTS,
									permissions: [PERMISSIONS.CONSULT]
								},
							},
							{
								path: "detail/:instrument?",
								name: "instrument-detail",
								component: InstrumentsView,
								beforeEnter: guardMyroute,
								meta: {
									title:
										messages[userLang].instrument &&
											messages[userLang].instrument.update.title_h
											? messages[userLang].instrument.update.title_h
											: "PREST",
									module: MODULES.INSTRUMENTS,
									dbModule: MODULES.INSTRUMENTS,
									permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
								}
							},
							{
								path: "section/detail/:section?",
								name: "section-detail",
								component: CreateInstrumentsSectionComponent,
								beforeEnter: guardMyroute,
								meta: {
									title:
										messages[userLang].instrument.add_section &&
											messages[userLang].instrument.add_section.title_h
											? messages[userLang].instrument.add_section.title_h
											: "PREST",
									module: MODULES.INSTRUMENTS,
									dbModule: MODULES.INSTRUMENTS,
									permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
								}
							},
							{
								path: "question/detail/:question?",
								name: "question-detail",
								component: CreateSectionQuestionComponent,
								beforeEnter: guardMyroute,
								meta: {
									title:
										messages[userLang].instrument.add_question &&
											messages[userLang].instrument.add_question.title_h
											? messages[userLang].instrument.add_question.title_h
											: "PREST",
									module: MODULES.INSTRUMENTS,
									dbModule: MODULES.INSTRUMENTS,
									permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
								}
							},
							{
								path: "objective-group/:instrument",
								name: "instrument-objective",
								component: InstrumentObjectiveGroup,
								beforeEnter: guardMyroute,
								meta: {
									title:
										messages[userLang].instrument_objective &&
											messages[userLang].instrument_objective.title_h
											? messages[userLang].instrument_objective.title_h
											: "PREST",
									module: MODULES.INSTRUMENTS,
									dbModule: MODULES.INSTRUMENTS,
									permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
								}
							},
							{
								path: "apply/:ext_token?",
								name: "answer-instrument",
								component: InstrumentAnswerView,
								meta: {
									title:
										messages[userLang].instrument_apply &&
											messages[userLang].instrument_apply.title_h
											? messages[userLang].instrument_apply.title_h
											: "PREST",
									module: MODULES.INSTRUMENT,
									dbModule: MODULES.INSTRUMENTS,
									permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
								}
							},
							{
								path: "preview/:instrument?",
								name: "instrument-preview",
								component: InstrumentPreviewView,
								beforeEnter: guardMyroute,
								meta: {
									title:
										messages[userLang].instrument &&
											messages[userLang].instrument_preview.title_h
											? messages[userLang].instrument_preview.title_h
											: "PREST",
									module: MODULES.INSTRUMENTS,
									dbModule: MODULES.INSTRUMENTS,
									permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
								}
							},
							{
								path: "template-instrument-list",
								name: "template-instrument-list",
								component: TemplateInstrumentsListView,
								beforeEnter: guardMyroute,
								meta: {
									module: MODULES.INSTRUMENTS,
									dbModule: MODULES.INSTRUMENTS,
									permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
								}
							},
						]
					},
					
					{
						path: '/smtp/:ext_token?',
						name: 'smtp',
						component: SMTPConfigView,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].smtp && messages[userLang].smtp.title ? messages[userLang].smtp.title : 'PREST',
							module: MODULES.SMTP,
							dbModule: MODULES.SMTP,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},
					{
						path: '/improvement-plan',
						name: 'improvement-plan',
						component: ImprovementPlanConfigView,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].improvements_plan && messages[userLang].improvements_plan.title ? messages[userLang].improvements_plan.title : 'PREST',
							module: MODULES.IMPROVEMENT_PLAN,
							dbModule: MODULES.IMPROVEMENT_PLAN,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},
					{
						path: '/improvement-plan/activity',
						name: 'activity-ip',
						component: ActivityIPFilterView,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].improvements_plan && messages[userLang].improvements_plan.title ? messages[userLang].improvements_plan.title : 'PREST',
							module: MODULES.IMPROVEMENT_PLAN,
							dbModule: MODULES.IMPROVEMENT_PLAN,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},
					{
						path: '/teachers-review',
						name: 'teachers-review',
						component: FinalReviewTeacherEvaluation,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].matrix && messages[userLang].matrix.title ? messages[userLang].matrix.title : 'PREST',
							module: MODULES.TEACHER_REVIEW,
							dbModule: MODULES.TEACHER_REVIEW,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},
					{
						path: '/review',
						name: 'review',
						component: FinalReviewMatrixView,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].review && messages[userLang].review.title ? messages[userLang].review.title : 'PREST',
							module: MODULES.REVIEW,
							dbModule: MODULES.REVIEW,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},
					{
						path: "/contract",
						component: ContractWrap,
						children: [
							{
								path: "",
								name: "contract-info",
								component: ContractView,
								beforeEnter: guardMyroute,
								meta: {
									title:
										messages[userLang].contract &&
											messages[userLang].contract.title_h
											? messages[userLang].contract.title_h
											: "PREST",
									module: MODULES.CONTRACT,
									dbModule: MODULES.CONTRACT,
									permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
								},
							},
							{
								path: "timeline-edit/:standardId?",
								name: "timeline-edit",
								component: TimelineCustomization,
								beforeEnter: guardMyroute,
								meta: {
									// title:
									// 	messages[userLang]. &&
									// 		messages[userLang]..title_h
									// 		? messages[userLang]..title_h
									// 		: "PREST",
									module: MODULES.CONTRACT,
									dbModule: MODULES.CONTRACT,
									permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
								},
							},
							{
								path: 'matrix-edit/:standardId?',
								name: 'matrix-edit',
								component: CustomizationMatrixView,
								beforeEnter: guardMyroute,
								meta: {
									title: messages[userLang].matrix && messages[userLang].matrix.title ? messages[userLang].matrix.title : 'PREST',
									module: MODULES.CONTRACT,
									dbModule: MODULES.CONTRACT,
									permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
								}
							},
							{
								path: "rubric-list/:standardId?",
								name: "rubric-list",
								component: RubricListView,
								beforeEnter: guardMyroute,
								meta: {
									// title:
									// 	messages[userLang]. &&
									// 		messages[userLang]..title_h
									// 		? messages[userLang]..title_h
									// 		: "PREST",
									module: MODULES.CONTRACT,
									dbModule: MODULES.CONTRACT,
									permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
								},
							},
							{
								path: "rubric/:rubricId?/:standardId",
								name: "rubric",
								component: RubricView,
								beforeEnter: guardMyroute,
								meta: {
									// title:
									// 	messages[userLang]. &&
									// 		messages[userLang]..title_h
									// 		? messages[userLang]..title_h
									// 		: "PREST",
									module: MODULES.CONTRACT,
									dbModule: MODULES.CONTRACT,
									permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
								},
							},
							{
								path: "add-contract-standard/",
								name: "add-contract-standard",
								component: AddContractStandard,
								beforeEnter: guardMyroute,
								meta: {
									// title: messages[userLang].matrix && messages[userLang].matrix.title ? messages[userLang].matrix.title : 'PREST',
									module: MODULES.CONTRACT,
									dbModule: MODULES.CONTRACT,
									permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION]
								},
							},
						]
					},
					{
						path: "/dashboard",
						component: DashboardWrap,
						children: [
							{
								path: "",
								name: "dashboard-quicksight",
								component: DashboardQuickSightView,
								beforeEnter: guardMyroute,
								meta: {
									title:
										messages[userLang].dashboard &&
											messages[userLang].dashboard.title_h
											? messages[userLang].dashboard.title_h
											: "PREST",
									module: MODULES.DASHBOARD,
									dbModule: MODULES.DASHBOARD,
									permissions: [PERMISSIONS.CONSULT]
								},
							},
						]
					},

					{
						path: '/reports',
						name: 'reports',
						component: ReportView,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].reports && messages[userLang].reports.title ? messages[userLang].reports.title : 'PREST',
							module: MODULES.MATRIX,
							dbModule: MODULES.MATRIX,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},

					{
						path: '/templates',
						name: 'templates',
						component: TemplatesView,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].matrix && messages[userLang].matrix.title ? messages[userLang].matrix.title : 'PREST',
							module: MODULES.TEMPLATES,
							dbModule: MODULES.TEMPLATES,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},
					{
						path: '/templates-eva-doc',
						name: 'templates-eva-doc',
						component: TemplatesEvaView,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].matrix && messages[userLang].matrix.title ? messages[userLang].matrix.title : 'PREST',
							module: MODULES.TEMPLATES,
							dbModule: MODULES.TEMPLATES,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},
					{
						path: '/templates-snies-2',
						name: 'templates-snies-2',
						component: TemplatesSnies2View,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].matrix && messages[userLang].matrix.title ? messages[userLang].matrix.title : 'PREST',
							module: MODULES.TEMPLATES,
							dbModule: MODULES.TEMPLATES,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},
					{
						path: '/notes',
						name: 'notes',
						component: NotesListComponent,
						beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].notes && messages[userLang].notes.title_h ? messages[userLang].notes.title_h: 'PREST',
							module: MODULES.NOTES,
							dbModule: MODULES.NOTES,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					},
					{
						path: '/my-notes',
						name: 'my-notes',
						component: MyNotesComponent,
						//beforeEnter: guardMyroute,
						meta: {
							title: messages[userLang].notes && messages[userLang].notes.my.titile ? messages[userLang].notes.my.titile: 'PREST',
							module: MODULES.MY_NOTES,
							dbModule: MODULES.MY_NOTES,
							permissions: [PERMISSIONS.CONSULT, PERMISSIONS.CREATION, PERMISSIONS.EDITION]
						}
					}
				],
			},
		],
	},
	{
		path: "/login",
		name: "login",
		beforeEnter: alreadyIsLogged,
		component: LoginView,
		meta: {
			module: MODULES.HOME,
			dbModule: MODULES.HOME,
			permissions: null
		}
	},
	{
		path: '/password-change/:signature?',
		name: 'password-change',
		beforeEnter: alreadyIsLogged,
		component: PasswordChangeView,
		meta: {
			title: messages[userLang].password_change && messages[userLang].password_change.title ? messages[userLang].password_change.title : 'PREST'
		}
	},

	{
		// path: "*",
		path: "/:catchAll(.*)",
		name: "NotFound",
		component: NotFoundView,
		meta: {
			module: MODULES.HOME,
			dbModule: MODULES.HOME,
			permissions: null
		}
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.afterEach((to, from) => {
	Vue.nextTick(() => {
		document.title = to.meta.title ? to.meta.title : "PREST";
	});
});

export default router;
