const messages = {
    en: {
    },
    es: {
        util: {
            load: 'Estamos preparando las cosas para ti. Solo necesitamos un momento más.',
            download_format_mail: 'Descargar formato de correos',
            closed_session: 'Sesión cerrada por inactividad',
            page_not_found: 'Página no encontrada',
            return_home: 'Volver al inicio',
            empty: 'No hay registros',
            table_filter: {
                label: 'Filtro',
                input_placeholder: 'Escriba para buscar...',
                button_label: 'Limpiar',
                checkbox_label: 'Filtrar por:',
                checkbox_description: 'Para filtrar en toda la base no seleccione campos.'
            },
            table_pagination: {
                label: 'Por página'
            },
            loading: 'Cargando ...',
            valid_select: 'Seleccione alguna opción',
            select_placeholder: 'Seleccione una opción',
            multiselect: {
                searching:'Buscar: %{field}',
                no_options: 'No hay registros'
            },
            document: {
                no_support: 'Archivo no soportado para visualizar.',
            },
            tree: {
                add_all: 'Añadir todos',
                remove_all: 'Eliminar todos',
                restore: 'Restablecer',
            },
            treeselect:{
                matrix: 'Selecciona un elementos de la matriz'
            },
            card_component:{
                add: "Registro añadido",
                edit: "Registro editado",
                delete: "Registro eliminado",
                no_edit: "No se puede editar el registro, este ya se encuentra en uso en otras partes del sistema.",
                no_delete: "No se puede eliminar el registro, este ya se encuentra en uso en otras partes del sistema.",
            },
            soon:  'Próximamente',
        },
        password_change: {
            title: 'Cambio de contraseña',
            subtitle: {
                send: 'Dígite su correo para el envío del correo de restauración de la contraseña',
                change: 'Dígite una nueva contraseña'
            },
            placeholder: 'Ingrese su correo electrónico',
            submit: 'Enviar',
            alert: {
                validation: {
                    message: '¡Se venció el enlace para el cambio de contraseña!',
                    message_strong: 'Solicite uno nuevo.'
                },

                message_sent: '¡El mensaje fue enviado exitosamente! Por favor revise su correo para continuar.',
                comparation: 'Las contraseñas no coinciden, por favor verificar'
            },
            new_password: {
                placeholder: 'Ingrese una nueva contraseña',
                confirmation_placeholder: 'Ingrese nuevamente la contraseña',
                show: "Mostrar",
                hide: "Ocultar"
            },
            email: {
                validator_message: 'Por favor ingrese una dirección de correo válida'
            }
        },
        general: {
            edit_process: 'Editar proyecto',
            contract: 'Contrato',
            program_label: 'Programa',
            programs: 'Programas',
            process_label: 'Proyecto',
            process_change: 'Usted acaba de seleccionar el proyecto &process.  Recuerde que de ahora en adelante los cambios que realice en la plataforma será sobre este proyecto, en caso que desee cambiar de proyecto lo podrá hacer en cualquier momento desde la parte superior',
            select_process: 'Seleccione un proyecto',
            select_process_desc: 'Seleccione el proyecto sobre el cual va a trabajar',
            no_process: 'Sin Proyectos en este contrato',
            current_process: 'Proyecto actual:',
            processes: 'Proyectos',
            process: 'Ficha del proyecto actual',
            select: 'Seleccionar',
            cancel: 'Cancelar',
            code: 'Código',
            name: 'Nombre',
            status: 'Estado',
            valid: 'Vigente',
            no_valid: 'No vigente',
            target: 'Objetivos del proyecto',
            dates: 'Fechas',
            supports: 'Soportes',
            no_supports: 'Sin soportes',
            change_process: 'Cambiar proyecto',
            change_contract: 'Cambiar Contrato',
            cycles: 'Ciclos del proyecto:',
            current_cycle: 'Ciclo actual:',
            policy: {
                title: 'TÉRMINOS Y CONDICIONES VIGENTES',
                validity: 'Vigente desde: ',
                checkbox_before: 'Yo acepto los términos y condiciones de PREST',
                checkbox_after: 'Yo acepto los términos y condiciones de ',
                checkbox_link: 'mi institución que pueden ser consultados acá',
                accept: 'Aceptar',
                cancel: 'Cancelar',
                accept_toast: 'Gracias',
                cancel_toast: 'Para ingresar a la plataforma es necesario que acepte, lo esperamos en otra ocasión.',
            },

        },
        home: {
            title_h: 'PREST - Evalúa',
            title_init: 'Bienvenida/o a',
            message_init: 'Comienza con tu configuración inicial, selecciona la opción con la que deseas iniciar.',
            process_button_label: 'Proyectos',
            schedule_button_label: 'Configuración del cronograma'
        },
        process: {
            title_h: 'Proyectos',
            title: 'Proyectos',
            title_asociated: 'Proyectos asociados',
            description: 'Lista de proyectos',
            show_all: 'Mostrar todos los proyectos',
            process_label: 'Proyecto',
            description_label: 'Descripción',
            report_label: 'Reporte',
            date: 'Tiempo de duración',
            date_expire: 'Fecha de expiración de registro',
            progress: 'Avance del proyecto',
            process: 'Proyecto',
            status: 'Estado',
            target: 'SNIES Objetivo del proyecto',
            person_in_charge: 'Responsable',
            no_process: 'No se encontró ningún resultado, verifique el filtro.',
			standard: 'Tipo de proyecto',
            no_standard: 'No se encontraron tipos de proyectos en su contrato, contacte con el proveedor.',
			view_process: 'Ver proyecto activos',
			view_process_prospect: 'Ver proyectos prospecto',
            view_process_asociated: 'Ver proyectos asociados',
            create: {
                title_alert_start_date:'Advertencia',
                accpet_current_date:'Aceptar',
                close_current_date:'Cerrar',
                alert_current_date:'La fecha de inicio no deberia ser anterior a la fecha actual.',
                note_collaborator: 'Recuerde que su usuario sera agregado como colaborador de manera predeterminada.',
                title: 'Nuevo Proyecto',
                title_asociated: 'Asociar proyecto',
                process: 'Proyecto',
                processes: 'Proyectos asociados',
                type_process: 'Tipo proyecto',
                start_date: 'Inicio del proyecto',
                settlement_date: 'Fecha de radicación',
                end_date: 'Fin del proyecto',
                target: 'SNIES Objetivo del proyecto',
                number_cycles: 'Número de ciclos',
                period: 'Periodo',
                name: 'Nombre del proyecto',
                description: 'Diligencia el siguiente formulario. Los espacios con <span style="color: red">*</span> son obligatorios',
                send: 'Guardar',
                close_cycle: 'Informe de autoevaluación',
                evaluation_cycle: 'Ciclo de evaluación',
                indication_info: 'Seguimientos',
                date: 'Fechas',
                indication: {
                    status: 'Estado',
                    detail: 'Detalle',
                    reported_percentage: 'Avance',
                    support_type: 'Soporte'
                },
                start: 'Fecha inicio',
                end: 'Fecha fin',
                title_h: 'Creación de proyecto',
                description_label: 'Descripción del proyecto',
                report_label: 'Nombre del Reporte',
                created: 'El proyecto &code fue creado exitosamente, continue con la configuración de ciclos.',
                continue: 'Continuar',
                back: 'Volver',
                add_collaborator: 'Añadir colaborador',
                name_invaled_cicle: {
                    more_500: 'El nombre excedió los 500 caracteres',
                    zero: 'El ciclo debe tener un nombre'
                },
                remove_self_collaborator: 'No puede eliminarse usted mismo como colaborador',
                collaborator: 'Colaborador',
                remove: 'Remover',
                action: 'Acción',
                add_program: 'Adicionar programa',
            },
            update_collaborators_message: 'Se han actualizado el proyecto',
            update_collaborators_modal: {
                title: 'Actualizar colaboradores',
                alert_message: 'Si elimina al responsable del proyecto y/o a un colaborador, este sera inhabilitado del proyecto y de todas las actividades de las cuales pudiera responsable.',
                save_button: 'Enviar',
                cancel_button: 'Cancelar'
            },  

            validation: 'El objetivo %{code} - %{targetLabel} se encuentra actualmente en el proceso %{processLabel}. Por favor retirelo, o cambie el tipo de proyecto al que lo está asociando.'
        },
        activity: {
            title_h: 'Actividades - Evalúa',
            title: 'Actividades',
            description: 'Lista de actividades en un curso próximo a iniciar.',
            show_all: 'Mostrar todas las actividades',
            activity_label: 'Actividad',
            description_label: 'Descripción',
            date: 'Tiempo de duración',
            progress: 'Avance de la actividad',
            person_in_charge: 'Responsable',
            q: 'Etiqueta',
            stage: 'Etapa',
            cycle: 'Ciclo',
            status: 'Estado',
            process: 'Proyecto',
            code: 'Código',
            valid: 'Vigente',
            no_valid: 'No vigente',
            return: 'Volver',
            no_activities: 'No se encontró ningún resultado, verifique el filtro.',
            action: 'Acción',
            pm: 'Plan de mejora',
            create: {
                title_h: 'Crear Actividad',
                title: 'Nueva Actividad',
                description: '',
                submit: 'Enviar',
                save: 'Añadir',
                valid: 'Validar',
                cancel: 'Cancelar',
                cycle_input: 'Proyecto y ciclo',
                stage_input: 'Etapa',
                activity_name_input: 'Actividad',
                activity_name_placeholder: 'Nombre de la actividad',
                dates_input_start: 'Fecha inicial',
                dates_input_end: 'Fecha final',
                description_input: 'Descripción',
                description_placeholder: 'Resumen',
                dates_cards: {
                    name: 'Fechas',
                    inputs: {
                        input_additional_date_name:{
                            on_edit_label: 'Nombre fecha',
                            to_show_label: 'Nombre fecha'
                        },
                        input_additional_date:{
                            on_edit_label: 'Fecha',
                            to_show_label: 'Fecha'
                        }
                    }
                },
                collaborators_cards: {
                    name: 'Colaboradores',
                    inputs: {
                        input_name:{
                            on_edit_label: 'Nombre usuario',
                            to_show_label: 'Usuario',
                            optionA: 'Seleccione un colaborador',
                        },
                        input_role:{
                            on_edit_label: 'Rol',
                            to_show_label: 'Rol',
                            optionA: 'Rol del colaborador',
                            optionB: 'Responsable Principal',
                            optionC: 'Colaborador'
                        }
                    }
                },
                objectives_cards: {
                    name: 'Objetivos',
                    inputs: {
                        input_name:{
                            on_edit_label: 'Nombre del objetivo',
                            to_show_label: 'Objetivo',
                        },
                        input_type:{
                            on_edit_label: 'Tipo',
                            to_show_label: 'Tipo',
                            optionA: 'Seleccionar un objetivo',
                            optionB: 'Objetivo',
                            optionC: 'Meta',
                            optionD: 'Entregable'
                        },
                        input_description:{
                            on_edit_label: 'Descripción',
                            to_show_label: '',
                        },
                        input_route:{
                            on_edit_label: 'URL',
                            to_show_label: '',
                        },
                    },
                    no_objectives: 'No hay objetivos'
                },
                resources_cards: {
                    name: 'Recursos',
                    inputs: {
                        input_name:{
                            on_edit_label: 'Nombre del recurso',
                            to_show_label: 'Recurso',
                        },
                        input_type:{
                            on_edit_label: 'Tipo',
                            to_show_label: 'Tipo',
                            optionA: 'Módulo',
                            optionB: 'Recurso externo (Imagen, Video, Instructivo, etc.)',
                        },
                        input_url_external_resource: {
                            on_edit_label: 'URL del recurso externo',
                            to_show_label: 'Recurso externo',
                        },
                        input_router: {
                            on_edit_label: 'Módulo',
                            to_show_label: 'Módulo',
                            optionA: 'Seleccionar Recurso'
                        },
                        input_description:{
                            on_edit_label: 'Descripción',
                            to_show_label: ''
                        }
                    },
                    no_resources: 'No hay recursos',
                    resource_creation_alert_message: 'Recurso añadido.',
                    resource_update_alert_message: 'Recurso actualizado.',
                    resource_delete_alert_message: 'Recurso eliminado.'
                },
                success_creation_alert_message: 'Actividad añadida exitosamente.',
                success_update_alert_message: 'Actividad actualizada exitosamente',
                success_delete_alert_message: 'Actividad eliminada exitosamente',
                error_creation_alert_message: 'Hubo un problema al crear la actividad.',
                error_resources_alert_message: 'No se puede añadir un tipo de recurso módulo sin un módulo, o seleccionar un tipo de recurso externo sin una dirección externa.',
                success_error_alert_message: 'Faltan datos por adicionar'
            },
            advance: {
                title_follow_up: 'Ver seguimientos de la actividad',
                follow_up: 'Ver seguimiento',
                title_register: 'Registrar avance de la actividad',
                register: 'Registrar Avance',
                save: 'Guardar Avance',
                add: 'Agregar',
                cancel: 'Cancelar',
                select_standard: 'Seleccionar un estándar',
                delete: 'Eliminar',
                process_not_equal: 'Para ver el seguimiento asegúrese estar dentro del proyecto: %{field}',
                status: {
                    text: 'Estado',
                    option_a: 'En inicio',
                    option_b: 'En proceso',
                    option_c: 'Terminada',
                    option_d: 'Cancelada',
                    option_e: 'Aplazada',
                },
                follow_up_date: 'Fecha',
                expected_advance: 'Avance esperado',
                reported_advance: 'Avance reportado',
                detail: 'Detalle',
                support_type: {
                    text: 'Tipo de soporte',
                    option_a: 'Porcentaje',
                    option_b: 'Dinero en pesos',
                    option_c: 'Número entero',
                    option_d: 'Número con decimales',
                    option_e: 'Documentos nuevos',
                    option_f: 'Enlace/Url',
                    option_g: 'Sin soporte',
                    option_h: 'Repositorio de documentos',
                },
                support: 'Soporte',
                supports: 'Soportes',
                decimals: {
                    text: 'Decimales',
                    option_a: 'Un Decimal',
                    option_b: 'Dos Decimales',
                    option_c: 'Tres Decimales',
                    option_0: '0',
                    option_1: '1',
                    option_2: '2',
                    option_3: '3'
                },
                util: {
                    detail_placeholder: 'Describa el avance logrado',
                    select_placeholder: 'Seleccione una opción',
                    accept_toast: 'Avance guardado en la actividad: %{field}',
                },
            },
            edit:{
                title: 'Editar Actividad'
            }
        },
        timeline: {
            configuration: {
                title: 'Configuración de cronograma',
                subtitle: 'Configuración de las actividades del cronograma. En esta sección puedes crear las actividades que se requieran para el cronograma.<br>Una etapa es un conjunto de actividades relacionadas que se realizan en un tipo de proyecto.<br/>Una actividad es una tarea específica que contribuye al avance de una etapa.<br/>Para ello, sigue estos pasos:<ul><li>Para crear una etapa, haz clic en el botón <strong>«Añadir etapa»</strong> y escribe el nombre y la descripción de la misma.<li>Para crear una actividad, primero selecciona la etapa a la cual va a pertenecer la actividad. Haz clic en el botón <strong>«Añadir actividad»</strong> y escribe el nombre, la descripción, los objetivos y los recursos.</li></ul>Recuerda que las actividades que fueron creadas por estándar serán generadas automáticamente para el cronograma del ciclo seleccionado y se sugerirá los rangos de fechas para la realización de las mismas.',
                valid: 'Validar',
                edit: 'Editar',
                delete: 'Eliminar',
                add: 'Añadir actividad',
                start_date: 'Fecha inicio',
                end_date: 'Fecha fin',
                settlement_date: 'Fecha radicación',
                activity: 'Actividad',
                activities: 'Actividades',
                stages: 'Etapas',
                stage: 'Etapa',
                stage_label: 'Nombre de la etapa',
                manager: 'Responsable',
                action: 'Acción',
                cycle: 'Ciclo',
                process: 'Proyecto seleccionado',
                other_cycle: 'Seleccionar otro ciclo',
                save_stage: 'Guardar etapa',
                required: 'Actividad obligatoria',
                yes: 'Si',
                cancel: 'Cancelar',
                delete_valid: 'Eliminar la actividad: ',
                delete_confirmation_modal: 'Eliminar actividad',
                delete_stage_confirmation_modal: 'Eliminar etapa',
                delete_stage_confirmation_label: '¿Desea eliminar la etapa: <strong>%{stage}</strong>?',
                edit_stage: 'Editar etapa',
                remove_stage: 'Borrar etapa',
                confirm: 'Confirmar',
                save: 'Aceptar',
                description: 'Descripción',
                description_label: 'Descripción de la etapa',
                add_stage: 'Añadir etapa',
                add_activity: 'Añadir actividad',
                add_activity_button: 'Añadir actividad',
                created: 'La etapa y  sus actividades fueron creados satisfactoriamente, continue con la configuración.',
                continue: 'Continuar',
                end_cycle: 'La etapa y  sus actividades fueron creados satisfactoriamente y todas las etapas de este ciclo fueron configuradas, continue con la configuración.'
            },
            alert:{
                remove_stage_alert: 'Por seguridad, debe borrar cada actividad antes de borrar la etapa',
                delete_activity: 'Actividad eliminada.',
                sucess_delete_stage_message: 'Etapa eliminada',
                error_delete_activity: 'Error al tratar de eliminar la actividad.'
            },
            configuration_standard:{
                title: 'Configuración de plantilla del cronograma',
                subtitle: 'En esta sección de configuración de la plantilla de un cronograma, puedes crear etapas y actividades para organizar tu proyecto.<br/>Una etapa es un conjunto de actividades relacionadas que se realizan en un tipo de proyecto.<br/>Una actividad es una tarea específica que contribuye al avance de una etapa.<br/>Para ello, sigue estos pasos:<ul><li>Para crear una etapa, haz clic en el botón <strong>«Añadir etapa»</strong> y escribe el nombre y la descripción de la misma.<li>Para crear una actividad, primero selecciona la etapa a la cual va a pertenecer la actividad. Haz clic en el botón <strong>«Añadir actividad»</strong> y escribe el nombre, la descripción, los objetivos y los recursos.</li></ul>Puedes crear tantas etapas y actividades como necesites para definir la plantilla de tu proyecto.<br/>Las fechas se designarán cuando crees un proyecto a partir de esta plantilla.',
                no_process: 'La plantilla no tiene ningún proceso base asociado. Por favor, comuníquese con el administrador para solucionar el problema.'
            }
        },
        timeline_dashboard:{
            title:'Dashboard linea del tiempo',
            subtitle:'Aquí podrá ver la línea del tiempo y el avance de sus proyectos'
        },
        login: {
            username: 'Usuario',
            email: 'Correo',
            password: 'Contraseña',
            submit: 'Ingresar',
            forgot_password: '¿Has olvidado la contraseña?',
            rebember: 'Recuérdame',
            title: 'Fortaleciendo la calidad educativa',
            subtitle: 'Sistema de evaluación'
        },
        user_profile: {
            name_input: "Nombre",
            lastname_input: "Apellidos",
            phone_input: "Teléfono",
            email_input: "Correo Electrónico",
            success_edit_profile_alert_message: "El perfil ha sido actualizado exitosamente.",
            send_email: {
                check_input: "Habilitar envío de correos",
                checked_message: "El usuario se encuentra habilitado para el envío de correos.",
                unchecked_message: "El usuario no se encuentra habilitado para el envío de correos."
            }
        },
        error: {
            '401_title': 'Datos incorrectos',
            '401_message': 'Correo o contraseña no son correctos.',
            '500_title': 'Error del sistema',
            '500_message': 'Intente de nuevo o contacte un administrador.'
        },

        calendar_timeline_view: {
            title: 'Cronograma',
            subtitle: 'Cronograma de actividades vistas en calendario/diagrama de Gantt'
        },
        filter: {
            apply_filter: 'Aplicar filtros',
            tooltip_list: 'Vista de lista',
            tooltip_mosaic: 'Vista mosaico',
            filters: 'Filtros',
            filter_action: 'Filtrar',
            select_one_option: 'Seleccione una opción'
        },

        collaborator: {
            title: 'Adicionar colaborador',
            subtitle: 'Buscar o crear colaborador para el proyecto',
            user_filter_card_title: 'Buscar colaborador',
            info_card_title: 'Información del colaborador',
            role_card_title: 'Rol del colaborador',
            not_found: 'No existe el colaborador',
            no_in_contract: 'Este colaborador no está en este contrato, ¿quieres invitarlo?',
            participant_collaborator_card_title: 'Buscar colaborador por participante',

            user_filter: {
                label: 'Buscar colaborador por número de identificación o correo electrónico',
                placeholder: 'Número de identificación o correo electrónico',
                not_valid_filter: 'Solo se puede filtrar por DNI o correo electrónico válido',
                search_button: 'Buscar',
                search_participant_button: 'Buscar participante',
                load_participant_button: 'Consultar participantes',
                clear_participant_button: 'Volver a colaboradores por contrato',
                not_allowed_participant_filter: "No existe el participante dentro de la lista de participantes. Si desea buscar un perfil que no se encuentre en la lista actual, por favor realice la búsqueda en colaboradores por contrato."
            },

            dni: {
                label: 'Número de documento',
                placeholder: 'Ingrese el número de documento'
            },
            
            name: {
                label: 'Nombres',
                placeholder: 'Ingrese su nombre'
            },
            
            last_name: {
                label: 'Apellidos',
                placeholder: 'Ingrese su apellido'
            },

            email: {
                label: 'Correo electrónico',
                placeholder: 'Ingrese su correo electrónico',
                not_valid_email: 'El campo %{field} debe ser un correo electrónico válido'
            },

            phone: {
                label: 'Teléfono',
                placeholder: 'Ingrese su número de teléfono'
            },

            charge: {
                label: 'Cargo',
                placeholder: 'Ingrese su cargo'
            },

            role: 'Rol',

            button_action: {
                register: 'Registrar',
                invite: 'Invitar al contrato',
                modify: 'Modificar',
                add: 'Agregar',
                delete: 'Eliminar',
                massive_assign: 'Asignación masiva',
			},
            massive:{
                method: 'Nivel de asignación',
                met_one: 'Ciclos',
                met_two: 'Etapas',
                cycle: 'Ciclo',
                collaborator: 'Colaborador',
                assign: 'Asignar',
                assigned: 'Asignación masiva completa',
                not_selected: 'Selecciona el colaborador, su rol y al menos una actividad',
                no_cycle: 'Seleccione un ciclo, para hacer la asignación en sus etapas.'
            },
            user_registration:{
                not_found_alert: 'No se ha encontrado ningún perfil con el correo electrónico o DNI dado. Puede registrar un nuevo usuario para ese correo o DNI, o puede registrar un usuario desde la lista de participantes del SNIES. ¿Desea ver la lista de usuarios disponibles de la lista de participantes?',
                success_message: 'El perfil ha sido creado. Se ha enviado un correo al usuario %{username} para su confirmación y activación',
                already_exists_message: 'Ya existe un perfil con este correo electrónico / DNI.',
                error_message: 'Error mientras se creaba el usuario: ',
                participant_collaborator_confirmation_modal: {
                    ok_button: "Aceptar",
                    cancel_button: "Cancelar"
                }
            },
            user_modification:{
                success_message: 'Se ha actualizado el perfil del usuario'
            },
            user_collaborator_invitation:{
                success_message: 'El usuario ha sido añadido al contrato como colaborador'
            },
            contract_state: {
                activate: 'Activar',
                deactivate: 'Inactivar',
                title: 'Afiliación del colaborador al contrato',
                end_date_label: 'Fecha de finalización de afiliación',
                modal_title: ' afiliación',
                modal_activation_body: '¿Desea habilitar la afiliación del colaborador?',
                modal_deactivation_body: '¿Desea inhabilitar la afiliación del colaborador?',
                ok_button: 'Aceptar',
                cancel_button: 'Cancelar',
                update_end_date_button: 'Actualizar fecha',
                activate_success_message: 'Se ha actualizado la afiliación del usuario',
                deactivate_success_message: 'Se ha inhabilitado la afiliación del usuario'
            }
		},
        muniment_history_process: {
            title_h: 'Histórico de Documentos finales del proceso',
            title: 'Histórico de Documentos finales del proceso',
            subtitle: 'Aquí encontrara la carpeta de documentos y soportes finales los cuales sustentan los procesos de: "%{field}".',
        },
        muniment: {
            title_h: 'Documentos y soportes del proyecto',
            title: 'Documentos y soportes del proyecto',
            subtitle: 'Aquí encontrara la carpeta de documentos y soportes los cuales sustentan la creación y debido proceso del proyecto.',

            button: {
                new: 'Nuevo',
                search: 'Repositorio',
                link: 'Enlace',
                file: 'Seleccionar archivo',
                addFile: 'Agregar versión',
                return: 'Volver',
            },

            origin: {
                type: 'Relación del Documento',
                support: 'Evidencias Generales',
                link: 'Link',
                evidence: 'Evidencia',
                annex: 'Anexo',
            },

            upload_modal: {
                title: 'Cargar documentos de soporte para el proyecto',
                input_file: {
                    placeholder: 'Elige un archivo o arrástralo y suéltalo aquí...',
                    drop_placeholder: 'Arrástra y suelta el archivo aquí...',
                    browser: 'Buscar',
                },

                label_placeholder: 'Ingrese el nombre del documento...',
                description_placeholder: 'Ingrese una descripción o nota...',
                source_placeholder: 'Ingrese la fuente del documento...',
                source_help: 'La fuente donde se obtiene u origina la información. Ejemplo: Bienestar, MEN, Etc.',
                save_button: 'Guardar documentos'
            },

            existing_modal: {
                title: 'Asociar soportes existentes'
            },

            data: {
                action: 'Acción',
                type: 'Tipo',
                no_type: 'Falta el Tipo',
                label: 'Nombre',
                no_label: 'Falta el Nombre',
                description: 'Descripción y notas',
                no_description: 'Falta la Descripción y notas',
                source: 'Fuente',
                no_source: 'Falta la Fuente',
                version: 'Versión',
                date: 'Fecha',
                start_date: 'Fecha inicial',
                end_date: 'Fecha final',
                file: 'Archivo',
                creation_user: 'Usuario',
                change: 'Motivo del cambio',

                util: {
                    change_placeholder: 'Motivo del cambio del soporte',
                    row_iterator: ' de la fila ',
                    evidence_delete: 'La evidencia solo se puede eliminar desde el módulo de definición de evidencias.'
                },

                type_options: {
                    empty: 'Seleccione una opción...',
                    policy: 'Política (Norma)',
                    plan: 'Plan',
                    research: 'Estudio',
                    report: 'Informe',
                    process: 'Proyecto / Procedimiento',
                    other: 'Otro',
                    statistic: 'Estadístico',
                    review: 'Análisis',
                    act: 'Acta',
                    agreement: 'Acuerdo',
                    contract: 'Convenio',
                    resolution: 'Resolución',
                    annex: 'Anexo',
                    gage: 'Indicadores',
                    documental: 'Documental',
                    appreciate: 'Apreciación',
                    self_assessment_report: 'Informe de autoevaluación',
                    self_assessment_matrix: 'Matriz de autoevaluación',
                    improvement_plan: 'Plan de mejoramiento',
                }
            },

            delete_confirm_message: '¿Está seguro de borrar el registro?',

            link_modal: {
                title: 'Cargar documentos de soporte para el proyecto a través de enlaces',
                text_area_placeholder: 'Ingrese los enlaces separados por coma (,)...',
                alert_duplicate: 'Los links duplicados serán ignorados.',
                alert_info: 'Por favor, digite el nombre y la descripción después de agregar todos los enlaces.',
            },

            not_valid_alert: {
                before: 'Los siguientes enlaces: ',
                after: '; no son válidos. Por favor revisarlos antes de continuar o no serán tenidos en cuenta en el registro.'
            },

            version: {
                link: 'Enlace',
                link_placeholder: 'Ingrese un nuevo Enlace',
                validation: {
                    link: 'El campo %{field} no es valido, este debería tener al inicio https://',
                }
            },
            quantity: {
                singular: 'archivo más',
                plural: 'archivos más'
            },
            error: {
                '409_message': 'El nombre del archivo es demasiado largo. Por favor, elija un nombre más corto y vuelva a intentarlo.'
            }
        },
        matrix:{
            title: 'Configuración de matriz',
            subtitle: 'Agregue y valide cada nivel de la matriz',
            add: 'Agregar',
            edit: 'Editar',
            delete: 'Borrar',
            save: 'Guardar',
            save_matrix: 'Guardar Matriz',
            cancel: 'Cancelar',
            code: 'Código',
            matrixCode: 'Código de la Matriz',
            name: 'Nombre',
            required: 'Obligatorio',
            detail: 'Detalle',
            select_other: 'Cambiar de ',
            change: 'Cambiar',
            created: 'Elementos de Matriz creados.', 
            end: 'Todos los elementos de la matriz fueron creados.',
            last_weigh: 'Recuerde que al momento de guardar la estrategia no podrá editar los parámetros ya establecidos. ¿Está seguro de guardar la matriz?.',
            evidence_weigh: '¿Está seguro de guardar los grupos establecidos para la definición y cargue de evidencias?.',
            valid_save_level: 'La matriz en consulta aún no se ha guardado, para  crear nuevos elementos dentro de esta, asegúrese de validar los elementos base generados por la plantilla, guardar y posteriormente crear lo elementos extra que desee.',
            already_valid: '¿Ya validó la matriz consultada?',
            next: 'Siguiente',
            prev: 'Anterior',
            dashboard:{
                title: 'Dashboard de seguimiento para la matriz',
                subtitle: '',
                no_matrix: 'El ciclo actual no cuenta con una matriz de evidencia configurada',
                config_matrix: 'Aquí puede configurarla',
                advance: 'Avance',
                
                matrix: 'Matriz de evidencia configurada para este ciclo',
                weighing_initial: 'Ponderación inicial',
                weighing_final: 'Ponderación final',
                evidence: 'Definición de evidencia',
                evidence_charge: 'Carga de evidencia',
                evidence_valoration: 'Valoración',
                no_weighing_initial: 'Sin ponderación inicial',
                no_weighing_final: 'Sin ponderación final',
                no_evidence: 'Sin definición de evidencia',
                no_evidence_charge: 'Sin carga de evidencia',
                no_evidence_valoration: 'Sin valoración'
            },
            alert: {
                no_delete: 'Para eliminar el elemento "%{field}", debe eliminar primero los elementos dependientes que están vinculados a él.'
            },
            custom: {
                title: 'Configuración de plantilla de la matriz',
                subtitle: 'Aquí puedes personalizar la plantilla de la matriz de autoevaluación añadiendo, editando o eliminando los elementos que quieras. Para ello, sigue estos pasos:<br/><ul><li>Para añadir un elemento, haz clic en el botón <strong>«Agregar ...»</strong> y escribe el codigó, nombre y la descripción de la misma.</li><li>Para editar un elemento, haz clic en el botón <strong>«<icon class="fa-solid fa-pencil" ></icon>»</strong> que aparece al lado del elemento que quieres modificar.</li><li>Para eliminar un elemento, haz clic en el botón <strong>«Eliminar»</strong> que aparece al lado del elemento que quieres quitar.</li></ul>Puedes crear tantos elementos como necesites para definir la plantilla de tu matriz.',
            }
        },
		exogenous: {
            title_h: 'Fuentes Exógenas',
			title: 'Fuentes Exógenas',
            subtitle: 'Estadísticas por fuentes externas reguladoras',
            total: 'Cantidad de registros: ',
            show_all: 'Mostrar todos los exógenos',
            create: {
                title: 'Agregar fuente'
            },
			fields: {
				source: 'Fuente',
                type: 'Tipo',
                label: 'Nombre',
				detail: 'Detalle',
				date: 'Fecha',
                file: 'Archivo',
			},
            options: {
                saces: 'SACES',
                snies: 'SNIES',
                spadies: 'SPADIES',
                scienti: 'SCIENTI',
                ole: 'OLE',
            },
            options_snies: {
                students_admitted: 'ESTUDIANTES ADMITIDOS',
                students_enrolled: 'ESTUDIANTES INSCRITOS',
                students_matriculate: 'ESTUDIANTES MATRICULADOS',
                teachers: 'DOCENTES',
                administrative: 'ADMINISTRATIVOS',
            },
            util: {
                select_placeholder: 'Seleccione una opción',
                label_placeholder: 'Nombre a visualizar',
                detail_placeholder: 'Detalle de la fuente exógena'
            },
            button: {
                new: 'Nuevo',
                suggest: 'Agregar Sugeridos',
                search: 'Repositorio',
                file: 'Seleccionar archivo',
                template: 'Descargar plantilla',
                addFile: 'Agregar Soporte',
                link: 'Enlace',
                continue: 'Culminar Tarea',
                add: 'Agregar',
                back: 'Regresar',
            },
            graph: {
                title_h: 'Gráfico de Fuentes Exógenas',
                number_records: 'Cantidad de registros: ',
                snies_adm: 'Cantidad de admitidos: ',
                back: 'Volver',
            }
        },
        weighing:{
            title: 'Ponderación',
            subtitle: 'Bienvenidos al proceso de ponderación, un componente esencial del Sistema de Aseguramiento de la Calidad, donde cada uno de ustedes, como valiosos miembros de la comunidad académica -estudiantes, profesores, egresados, directivos, administrativos y representantes del sector productivo-, jugará un papel crucial. Les invitamos a determinar el nivel de importancia de los Factores y Características de calidad, asignando un peso específico a cada uno, en una escala de 10 a 100, donde 10 denota menor importancia y 100 la máxima. Se requiere adicionalmente que justifiquen las razones detrás de cada ponderación asignada. En los casos que el nivel de importancia que asignen los integrantes de los diferentes grupos de ponderación, presente una variación superior al 20%, será necesario analizar y llegar a consensos entre los participantes. Su colaboración es vital en este ejercicio integral de evaluación y mejora continua. ¡Agradecemos su compromiso y colaboración!',
            find: 'Buscar',
            valid_met_three: 'Al seleccionar este nivel de la matriz deberá ponderar %{count} elementos. ¿Está seguro de seleccionar este nivel de la matriz?',
            met_one: 'Ponderación inicial.',
            met_two: 'Ponderación por niveles de importancia.',
            met_three: 'Ponderación final.',
            met_consult: 'Consultar ponderación',
            no_weigh: 'Sin Ponderar',
            weighing: 'Ponderar',
            consult: 'Consultar',
            assign_w_one: 'Asigne la ponderación inicial del %{element}',
            assign_w_two: 'Asigne un nivel de importancia entre 10 y 100 que determine cuán importante es el %{element} para el proyecto de autoevaluación',
            assign_w_three: 'Asigne la ponderación final del %{element}',
            w_one: 'Ponderación inicial dada por el responsable: ',
            w_two: 'Calificación promedio dada por el grupo: ',
            w_notes: 'Justificar la ponderación o nivel de importancia que se está asignando al %{element}',
            w_value: 'Nivel de importancia',
            w_see_notes: 'Justificar la ponderación o nivel de importancia que se está asignando al %{element} dadas por el grupo',
            available_percent: 'Porcentaje disponible ',
            valid_weigh: 'Asigne un nivel de importancia a la ponderación.',
            save_weigh: 'La ponderación del %{element} de la matriz guardado correctamente.',
            end_weigh: 'La ponderación de esta matriz ha terminado.',
            last_weigh: 'Este es el último %{element} de la matriz a ponderar, al guardarlo se dará la ponderación como cerrada y no podrá editar. ¿Está seguro de terminar? Al responder “NO” puede regresar a verificar los elementos sin perder los cambios y cuando desee culminar la tarea pondere nuevamente este elemento, en caso de que ya se realizará la validación responda “SI” para enviar los resultados finales.',
            save: 'Guardar',
            save_all: 'Generar la ponderación de manera automática',
            save_all_group: 'Generar la ponderación con base en la ponderación por niveles de importancia',
            saved_all: 'Generada la ponderación de manera automática',
            error_saved_all: 'Error al generar la ponderación de manera automática, intenta nuevamente.',
            confirm_save_all: 'Está seguro de generar la ponderación de manera automática, al guardarlo se dará la ponderación como cerrada y no podrá editar.',
            accept: 'Aceptar',
            yes: 'Si',
            no: 'No',
            no_matrix: 'No hay una matriz configurada para este ciclo del proyecto.',
            ext_weighing_end: 'Gracias, su ponderación asignada ya ha terminado.',
            equal: ' que equivale a ',
            without_value: 'Verifique la ponderación de los otros elementos de la matriz, ya que se completo el 100%',
            config:{
                title: 'Estrategia de ponderación',
                edit_tab: 'Editar estrategia',
                copy_tab: 'Heredar ponderación',
                group_title: 'Asignación de grupo',
                level: 'Nivel de matriz',
                method: 'Método de asignación',
                met_one: '1. Asignación de ponderación inicial por parte del responsable.',
                met_two: '2. Asignación de ponderación por parte de grupo experto asignando niveles de importancia sobre los elementos disponibles.',
                met_three: '3. Asignación final y definitiva por parte del responsable',
                scale_weighing: 'Escala de ponderación',
                groups: 'Grupos asignados',
                assign_group: 'Asignar grupo',
                no_groups: 'Debe asignar al menos a un grupo la ponderación',
                fail_method: 'Error al crear el método de ponderación, intente nuevamente.',
                success_method: 'El método de ponderación fue creado.',
                term: 'Plazo',
                term_start: 'Inicio',
                term_end: 'Fin',
                group_name: 'Grupo',
                groups_list: 'Seleccione un grupo',
                objective: 'Objetivos',
                add_all: 'Seleccionar todos',
                edit_config: 'Editar estrategia de ponderación',
                see_advance: 'Ver avances de ponderación',
                advance_title: 'Avance de ponderación',
                weighing_one_advance_card_title: 'Ponderación Inicial',
                weighing_two_advance_card_title: 'Ponderación por grupo',
                weighing_three_advance_card_title: 'Ponderación Final',
                participants: 'Participantes',
                participant: 'Participante',
                advance: 'Avance',
                assign: 'Asignar',
                advance_total: 'Avance total',
                file: 'Cargar lista de correos de participantes',
                add_participants: 'Agregar participante',
                not_valid_format: 'El formato de archivo es invalido, verifique que sea solo una columna con los correos y de titulo "Correos".',
                invalid_file_type: 'El archivo seleccionado no es un archivo XLSX válido.',
                file_read_error: 'Error de lectura de archivo.',
                no_file_selected: 'No se ha seleccionado ningún archivo. Por favor, selecciona un archivo.',
                missing_email: 'Falta el campo de correo electrónico en una o más filas.',
                no_group_select: 'Seleccione un grupo a asignar.',
                no_end_date: 'Seleccione una fecha final para el plazo de ponderación',
                no_participants: 'Tiene que haber mínimo un participante',
                no_targets: 'Tiene que estar seleccionado objetivo a ponderar',
                copy_weighing: {
                    select_label: 'Heredar ponderación desde ciclo / matriz',
                    copy_button: 'Heredar ponderación',
                    origin_level_label: 'Nivel origen',
                    destiny_level_label: 'Nivel destino',
                    final_weight: 'Peso heredado',
                    confirmation_description: 'El ciclo actual recibirá los porcentajes de la ponderación final del ciclo seleccionado hasta el nivel que haya sido realizada, y será guardado como el ejercicio completado de ponderación final el cual no podrá ser editado nuevamente.',
                    confirmation_label: '¿Desea copiar la ponderación?'
                },
                weighing_two_advance_table: {
                    matrix_level: 'Nivel Matriz',
                    average: 'Peso consolidado',
                    std_dev: 'D. Estándar',
                    variance: 'Varianza',
                    var_coef: 'Coef. de Var.',
                    no_weigh: 'Sin Ponderar',
                    total_invitations: 'N. Participantes'
                }
            }
        },
        evidence:{
            support_modal: 'Carga de evidencia desde soportes',
            charge_by_support: 'Carga de evidencia desde soportes',
            matrix: 'Elementos de la matriz asignados',
            append_annex: 'Agregar anexo',
            yes: 'Si',
            no: 'No',
            valid_met_three_evidence: 'Al seleccionar este nivel de la matriz deberá cargar evidencia &count elementos. ¿Está seguro de seleccionar este nivel de la matriz?',
            valid_met_three_valoration: 'Al seleccionar este nivel de la matriz deberá valorar &count elementos. ¿Está seguro de seleccionar este nivel de la matriz?',
            title: 'Matriz de evidencias',
            title_valoration: 'Valoración de evidencias',
            subtitle: '',
            no_matrix: 'No hay una matriz configurada para este ciclo del proyecto.',
            no_matrix_valoration: 'No hay una matriz configurada para este ciclo del proyecto o no ha configurado la carga de evidencias, verifique y vuelva cuando cumpla estos requisitos.',
            evidence: 'Evidencias',
            met_one: 'Definición de evidencia.',
            met_two: 'Carga de evidencia',
            met_three: 'Valoración de evidencias',
            met_one_v: 'Definir la evidencia.',
            define_evidence: 'Evidencias definidas <span>%{size}</span>',
            met_two_v: 'Cargar la evidencia',
            charge_evidence: 'Evidencias cargadas <span>%{charge} de %{size}</span>',
            all_evidence: 'Todas las evidencias cargadas',
            met_three_v: 'Valorar la evidencia',
            valoration_v: 'Valorar desde %{can}',
            value_evidence: 'Evidencia valorada <span>%{charge} - %{size}</span>',
            no_evidence: 'Sin evidencia',
            no_evidence_define: 'Sin evidencias definidas',
            no_evidence_charge: 'Sin evidencias cargadas',
            no_evidence_value: 'Sin evidencias valoradas',
            no_valoration: 'Sin valoración',
            no_value: 'Sin valoración',
            valoration_next: 'No se puede realizar la valoración desde "%{matriz}". Solo se puede valorar desde %{can}.',
            met_consult: 'Consultar matriz de evidencias',
            consult: 'Ver evidencias',
            w_notes: 'Comentarios o recomendaciones frente al elemento evaluado',
            support_type: 'Tipo de soporte',
            support_name: 'Filtro: nombre del soporte',
            support_new: 'Nuevo soporte',
            support_process: 'Soportes del Proyecto',
            support_all: 'Todos los soportes',
            save_evidence: 'Evidencia guardada',
            save_evidence_support: 'Soporte cargado',
            save: 'Guardar',
            cancel: 'Cancelar',
            see: 'Ver evidencia',
            no_support: 'Sin archivo',
            add:'Crear soporte',
            edit: 'Editar soporte',
            edit_support_button: 'Editar',
            delete_support_button: 'Eliminar',
            delete_support_label: 'Si elimina el soporte, la definición de la evidencia que haya realizado del mismo en cada nivel de la matriz también será eliminada. ¿Desea eliminar el soporte?',
            success_edit_message: 'Se ha actualizado el soporte exitosamente',
            success_delete_message: 'Se ha eliminado el soporte exitosamente',
            assign: 'Definir evidencia',
            my_supports: 'Evidencias de la matriz',
            my_supports_child: 'Evidencias de los siguientes nivel de la matriz',
            see_matrix: 'Ver detalle del matriz',
            valid_evidence: 'Asigne al menos una evidencia',
            no_assign: 'No tiene definidas evidencias para este elemento de la matriz, antes de hacer el cargue, verifique la definición de las evidencias.',
            valid_charge: 'Cargue las evidencias',
            valid_assessment: 'No se ha valorado',
            end_evidence: 'La valoración de esta matriz ha terminado.',
            last_evidence: 'Este es el ultimo elemento de la matriz al cual definir le evidencia, al guardarlo se dará la matriz como cerrada y no podrá editar. Está seguro de terminar',
            create_support: 'Crear soporte',
            charge_file: 'Cargar soporte',
            charge_new: 'Cargar nuevo archivo',
            charge_new_version: 'Cargar nueva versión de archivo',
            delete_muniment: 'Eliminar archivo',
            save_permanently: 'Guardar definitivamente',
            ext_evidence_end: 'Gracias, la carga de evidencia  ha terminado.',
            ext_valoration_end: 'Gracias, la valoración ha terminado.',
            assessment: 'Valoración',
            assessment_level: 'Valore el %{element} en una escala de 0 a 5',
            appreciate: 'Valorar',
            value_1_documental: 'No se cumple',
            value_2_documental: 'Se cumple insatisfactoriamente',
            value_3_documental: 'Se cumple aceptablemente',
            value_4_documental: 'Se cumple en alto grado',
            value_5_documental: 'Se cumple plenamente',
            value_1: 'No se cumple',
            value_2: 'Se cumple insatisfactoriamente',
            value_3: 'Se cumple aceptablemente',
            value_4: 'Se cumple en alto grado',
            value_5: 'Se cumple plenamente',
            value_insert: 'Ingrese un valor de 0 a 5',
            charge_type: {
                tab1: 'Archivo',
                tab2: 'Enlace',
            },
            config:{
                title_evidence: 'Estrategia de Definición y Carga de evidencias',
                title_valoration: 'Estrategia de valoración',
                group_title: 'Asignación de grupo',
                level: 'Nivel de matriz',
                rubrics: 'Rubricas por soporte',
                matrix_rubrics: 'Rubricas a utilizar',
                method: 'Método de definición',
                method_valoration: 'Método de valoración',
                met_one: '1. Definición de evidencias.',
                met_two: '2. Carga de evidencias.',
                met_three: '3. Valoración de evidencias',
                scale_evidence: 'Escala de valoración',
                groups_assign: 'Grupos asignados a la definición de evidencias',
                groups: 'Grupos asignados a carga de evidencias',
                groups2: 'Grupos asignados a valoración',
                assign_group: 'Asignar grupo',
                no_groups: 'Debe asignar al menos a un grupo la estrategia de carga de evidencias',
                no_groups_valoration: 'Debe asignar al menos a un grupo la estrategia de valoración',
                fail_method: 'Error al crear la estrategia de carga de evidencias, intente nuevamente.',
                fail_method_valoration: 'Error al crear la estrategia de valoración, intente nuevamente.',
                success_method: 'La Estrategia de carga de evidencias fue creado.',
                success_method_valoration: 'La Estrategia de carga de valoración fue creado.',
                term: 'Plazo',
                term_start: 'Inicio',
                term_end: 'Fin',
                group_name: 'Grupo',
                groups_list: 'Seleccione un grupo',
                objective: 'Objetivos',
                participants: 'Participantes',
                participant: 'Participante',
                advance: 'Avance',
                assign: 'Asignar',
                advance_total: 'Avance total',
                file: 'Cargar lista de correos de participantes',
                add_participants: 'Agregar participante',
                not_valid_format: 'El formato de archivo es invalido, verifique que sea solo una columna con los correos y de titulo "Correos".',
                no_group_select: 'Seleccione un grupo a asignar.',
                no_end_date: 'Seleccione una fecha final para el plazo de estrategia de carga de de evidencias y valoración',
                no_participants: 'Tiene que haber mínimo un participante',
                no_targets: 'Tiene que estar seleccionado objetivo a cargar evidencias y valorar',
                no_rubrics_message: 'No tiene rubricas configuradas en el estándar del proceso actual',
                see_advance: 'Ver avance de la definición y carga de evidencia',
                advance_title: 'Avance de la definición y carga de evidencia',
                see_advance_valoration: 'Ver avance de valoración de evidencia',
                advance_title_valoration: 'Avance de valoración',
                specific_advance:{
                    participant_valoration:'Valoración por participante',
                    valoration_advance_tab:'Avance por participante',
                    level_valoration: 'Valoración por nivel',
                    valoration_graphic_advance: 'Gráfica del avance de valoración',
                    valoration_graphic_table_tab:'Tabla',
                    valoration_graphic_table:'Tabla de valoración actual',
                    valorated_percentage:'Porcentaje valorado',
                    average_assessment:'Promedio de las valoraciones',
                    valuator:'Valorador',
                    assessment:'Valoración'
                },
                edit_config: 'Editar estrategia de definición y carga de evidencia',
                edit_config_valoration: 'Editar estrategia de valoración de evidencia',
                invalid_value: 'Valor inválido. Debe estar entre 0 y 5.',
                valoration_options: {
                    method_one: {
                        label: 'Bajo nivel de objetividad',
                        description: 'La valoración se realiza asignando una nota directamente al nivel que se está evaluando.'
                    },
                    method_two: {
                        label: 'Alto nivel de objetividad',
                        description: 'En este nivel, no se valora directamente el nivel, sino los documentos que lo componen. Estos se califican mediante una rúbrica específica para cada tipo de documento.'
                    },
                    method_three: {
                        label: 'Medio nivel de objetividad',
                        description: 'La valoración se asigna al nivel evaluado a través de una rúbrica, proporcionando una evaluación más estructurada.'
                    },
                },
            },
            valoration_rubric:{
                more_info: 'Aquí puedes encontrar mas información relacionado a la pregunta.',
                decimals_number: 'El valor del campo debe tener máximo %{decimal} decimales.'
            }
        },
        instrument:{
            title_h: 'Lista de Instrumentos',
            title: 'Instrumentos',
            subtitle: 'Lista de Instrumentos',
            show_all: 'Mostrar todos los instrumentos',
            instrument_label: 'Instrumentos',
            description_label: 'Detalle',
            instrument_code_and_title: 'Código / Titulo del instrumento',
            publication_date: 'Fecha publicación',
            close_date: 'Fecha cierre',
            popover: {
                publication_date: 'Fecha en que fue publicada la encuesta',
                close_date: 'Fecha en que fue cerrada o despublicada la encuesta',
            },
            create_instrument_option_modal: {
                title: 'Crear instrumento',
                body: 'Crear un nuevo instrumento a partir de una plantilla, o un nuevo instrumento vacío.',
                create_instrument_from_template_button: 'Crear desde plantilla',
                create_new_instrument_button: 'Crear nuevo instrumento'
            },
            create: {
                title_h: 'Crear instrumento',
                title: 'Nuevo instrumento',
            },
            update: {
                title_h: 'Editar instrumento',
                title: 'Editar instrumento'
            },
            readonly:{
                title_h: 'Ver Instrumento',
                title: 'Instrumento <b>%{instrument}</b>'
            },
            code: 'Código',
            state: 'Estado',
            presentation_mode: 'Método de presentación',
            instrument_title: 'Título',
            description: 'Propósito del instrumento',
            instrument_cycle: 'Ciclo del instrumento',
            random: 'Presentar las preguntas de manera aleatoria',
            answer_range: 'Rango de valor para las preguntas',
            manage_sections: 'Añadir Sección',
            save_instrument_button: 'Guardar',
            publish_instrument_button: 'Publicar Instrumento',
            inivite_instrument_button: 'Invitar participantes al Instrumento',
            set_template_instrument_button: 'Asignar instrumento como plantilla',
            set_groups_sample_button: 'Muestra esperada',
            config_instrument_matrix: 'Configurar matriz',
            delete_instrument_button: 'Eliminar instrumento',
            exit_instrument_button: 'Volver',
            publish_instrument_modal:{
                title: 'Publicar instrumento',
                publication_date: 'Fecha aplicación',
                current_date_button: 'Hoy',
                end_of_day_button: 'Fin del día',
                close_date: 'Fecha cierre',
                welcome_message_label: 'Mensaje de Bienvenida',
                ferewell_message_label: 'Mensaje de Despedida',
                welcome_message: 'Bienvenido/a',
                ferewell_message: 'Gracias por su participación',
                welcome_message_placeholder: 'Mensaje de Bienvenida',
                ferewell_message_placeholder: 'Mensaje de Despedida',
                empty_message_feedback: 'El campo %{field} es obligatorio.',
                max_length_message_feedback: 'Se ha excedido el número máximo de elementos en el texto',
                ferewell_message_feedback_message: 'El campo Mensaje de Despedida es obligatorio.',
                justification: {
                    not_published: 'Apertura de instrumento.',
                    pubished: 'Actualización fecha de cierre de instrumento.',
                    reopen: 'Solicitud de reapertura de instrumento (cambio fecha de cierre): '
                },
                reopen_justification_label: 'Justificación de solicitud',
                reopen_justification_placeholder: 'Ingrese la justificación de la solicitud de reapertura del instrumento',
                publish_button: 'Publicar',
                cancel_button: 'Cancelar',
                reopen_button: 'Reabrir instrumento',
                reopen_checkbox: 'Reabrir instrumento',
                close_date_button: 'Aceptar',
                publish_dates_validation_message: 'No puede publicar el instrumento sin fecha de publicación y sin fecha de cierre.',
                publish_success_message: 'Se ha publicado el instrumento exitosamente.',
                update_close_date_success_message: 'Se ha actualizado la fecha de cierre exitosamente.',
                reopen_success_message: 'Se ha realizado la solicitud de reapertura exitosamente.',
                publish_error_message: 'Ocurrió un problema mientras se publicaba el instrumento: ', 
            },
            set_template_instrument:{
                title: 'Asignar instrumento como plantillas',
                body: 'Recuerde que al habilitar este instrumento como plantilla cualquier cambio que haga sobre el mismo se verá reflejado solo en los instrumentos creados posteriormente al cambio. Adicionalmente, esta plantilla estará habilitada en los diferentes estándares usados por su institución y en caso de que dicho estándar no tenga coincidencias en los códigos de matriz y grupos objetivo, la pregunta requerirá que estos dos elementos sean configurados sobre el nuevo estándar destino. ¿Desea asignar el instrumento para su uso como plantilla?',
                body_note: 'Nota: El instrumento debe estar creado para poder asignarse como plantilla',
                ok_button: 'Aceptar',
                cancel_button: 'Cancelar'
            },
            delete_instrument_modal:{
                title: 'Eliminar instrumento',
                warning_message: 'Se eliminarán todas las secciones y preguntas asociadas al instrumento. ¿Desea eliminar el instrumento?',
                delete_button: 'Eliminar',
                cancel_button: 'Cancelar'
            },
            set_groups_sample_modal: {
                title: 'Asignar muestra esperada',
                ok_button: 'Aceptar'
            },
            config_instrument_matrix_label: 'Asociar nivel de matriz al instrumento',
            config_instrument_matrix_modal: {
                title: 'Configurar niveles de la matriz',
                iteration_matrix_card: {
                    title: 'Matriz de evidencias',
                    body: 'Seleccione los niveles de la matriz.'
                },
                cross_cutting_matrix_card:{
                    title: 'Configurar matrices transversales',
                    add_button: 'Usar matriz',
                    already_exist_warning_message:'Ya fue añadida esta matriz para su configuración.',
                    not_selected_error_message: 'Debe seleccionar una matriz para poder ser añadida.'
                },
                typeLevels: 'Niveles de la matriz',
                ok_button: 'Aceptar',
                cancel_button: 'Cancelar'
            },
            no_config_instrument_matrix_message: 'No se ha guardado una configuración de matriz para el proyecto actual.',
            state_options: {
                optionA: 'Seleccionar un estado',
                optionB: 'Activo',
                optionC: 'Inactivo'
            },
            presentation_options: {
                optionA: 'Seleccionar un método de presentación',
                optionB: 'Permitir una única oportunidad para registrar respuestas',
                optionC: 'Permitir múltiples oportunidades para registrar respuestas'
            },
            cycle_select_options: {
                default: 'Seleccionar ciclo'
            },
            answer_range_options: {
                default: 'Seleccionar rango',
                optionA: '[%{min} - %{max}]',
                optionB: '[%{min} - %{max}]'
            },
            confirmation_alert:{
                go_instrument_section_button: 'Completar instrumento actual',
                close_button: 'Cerrar',
                success_message: 'Instrumento creado con éxito.',
                error_message: 'Ocurrió un problema mientras se creaba el instrumento:',
                dates_error_message: 'La fecha de cierre es mayor a la fecha de publicación. Por favor actualice las fechas.'
            },
            add_section:{
                create:{
                    title_h: 'Crear Sección',
                    title: 'Nueva Sección'
                },
                update:{
                    title_h: 'Editar Sección',
                    title: 'Editar Sección'
                },
                readonly:{
                    title_h: 'Ver Sección',
                    title: 'Sección <b>%{section}</b>'
                },
                not_available_creation: 'No se puede crear una sección mientras el instrumento ya se encuentre abierto dentro de la fecha de publicación.',
                code: 'Código',
                state: 'Estado',
                order: 'Orden',
                section_title: 'Título',
                user_notes: {
                    label: 'Notas para el usuario',
                    default_text: 'Notas para el usuario',
                    max_length_message_feedback: 'Se ha excedido el número máximo de elementos en el texto'
                },
                description: 'Propósito de la sección',
                buttons: {
                    save: 'Guardar',
                    add: 'Añadir preguntas',
                    delete_section: 'Eliminar sección',
                    exit: 'Volver'
                },
                delete_section_modal:{
                    title: 'Eliminar sección',
                    warning_message: 'Se eliminarán las preguntas asociadas a la sección. ¿Desea eliminar la sección?',
                    delete_button: 'Eliminar',
                    cancel_button: 'Cancelar'
                },
                state_options:{
                    optionA: 'Seleccione un estado',
                    optionB: 'Activo',
                    optionC: 'Inactivo'
                },
                change_state_modal:{
                    warning_message: '<b>Nota:</b> Si cambia el estado de la sección mientras el instrumento este publicado, la sección y sus preguntas ya no seran requeridas en el instrumento y no se podra volver a habilitar.',
                    ok_button: 'Aceptar',
                    cancel_button: 'Cancelar'
                },
                order_option: {
                    optionA: 'Seleccione el orden de la sección'
                },
                confirmation_alert:{
                    add_new_section_button: 'Nueva sección',
                    close_button: 'Cerrar',
                    success_message: 'La sección fue añadida con éxito.',
                    error_message: 'Ocurrió un problema mientras se añadida la sección al instrumento:', 
                },
                add_question_validation_alert:{
                    section_data_validation_error_message: 'No se puede añadir una pregunta a una sección sin nombre.'
                }
            },
            add_question:{
                create:{
                    title_h: 'Crear Pregunta',
                    title: 'Nueva Pregunta',
                },
                update:{
                    title_h: 'Editar Pregunta',
                    title: 'Editar Pregunta',
                },
                readonly:{
                    title_h: 'Ver Pregunta',
                    title: 'Pregunta <b>%{question}</b>'
                },
                not_available_creation: 'No se puede crear una pregunta mientras el instrumento ya se encuentre abierto dentro de la fecha de publicación.',
                code: 'Código',
                state: 'Estado',
                order: 'Orden',
                section: 'Sección',
                question: 'Pregunta',
                help: 'Ayuda',
                group: 'Grupo objetivo',
                type: 'Tipo pregunta',
                no_type_allowed_description: 'No hay descripción disponible',
                default_value: 'Valor por defecto',
                is_required: 'Pregunta obligatoria',
                require_justification: 'La respuesta requiere justificación',
                answer_decimals: 'Decimales en respuestas',
                final_evaluation: 'No interfiere en la calificación final de la percepción',
                associate_matrix:{
                    label: 'Asociar / consultar niveles de matriz',
                    button: 'Asociar niveles',
                    button_subquestion: 'Asociar niveles a subpregunta',
                    not_found_matrix: 'Aún no se han configurado los niveles de la matriz para asociar.',
                    modal:{
                        parent_question_title: 'Asignar niveles de matrices',
                        child_question_title: 'Asignar niveles de matrices a subpregunta',
                        ok_button: 'Aceptar',
                        close_button: 'Cerrar'
                    }
                },
                delete_question_modal:{
                    title: 'Eliminar pregunta',
                    warning_message: 'Se eliminará la pregunta del instrumento actual. ¿Desea eliminar la pregunta?',
                    delete_button: 'Eliminar',
                    cancel_button: 'Cancelar'
                },
                change_type_modal:{
                    title: 'Cambiar tipo de pregunta',
                    warning_message: 'Actualmente la pregunta tiene asignado un tipo y opciones de respuesta. Si cambia el tipo de pregunta las opciones serán eliminadas al guardar la pregunta. ¿Desea cambiar el tipo de pregunta?',
                    accept_change_button: 'Aceptar',
                    cancel_button: 'Cancelar'
                },
                state_options:{
                    optionA: 'Seleccione el estado de la pregunta',
                    optionB: 'Activo',
                    optionC: 'Inactivo'
                },
                change_state_modal: {
                    warning_message: '<b>Nota:</b> Si cambia el estado de la pregunta mientras el instrumento este publicado, la pregunta ya no sera requerida en el instrumento y no se podrá volver a habilitar.',
                    ok_button: 'Aceptar',
                    cancel_button: 'Cancelar'
                },
                order_options: {
                    optionA: 'Orden'
                },
                section_options: {
                    optionA: 'Seleccione la sección'
                },
                type_options: {
                    optionA: 'Seleccione el tipo de pregunta'
                },
                group_all_options: 'Todos',
                group_options: {
                    optionA: 'Grupo de apoyo especializado',
                    optionB: 'Egresados',
                    optionC: 'Externos',
                    optionD: 'Directivos',
                    optionE: 'Administrativos',
                    optionF: 'Docentes',
                    optionG: 'Alumnos'
                },
                range: {
                    min: 'Rango mínimo',
                    max: 'Rango máximo',
                    step: 'Paso',
                    status: '¿Visible?',
                    decimals: 'Decimales'
                },

                bool: {
                    option: {
                        title: 'Opción %{index}',
                        label: 'Opción',
                        weight: 'Peso'
                    },
                },

                validation: {
                    greater_than: 'El valor de este campo debe ser mayor al valor del campo "%{field}".',
                    module_of: 'El valor de este campo debe ser divisor del valor del campo "%{field}".',
                    in_range: 'El valor de este campo debe estar entre %{min} y %{max}',
                    decimals_number: 'El valor del campo debe tener máximo %{decimal} decimales.'
                },

                assessment: {
                    positive: {
                        title: 'Positiva',
                        label: 'Opción',
                        default: 'Fortalezas'
                    },

                    negative: {
                        title: 'Negativa',
                        label: 'Opción',
                        default: 'Oportunidades de mejora'
                    }
                },
                save_question_button: 'Guardar pregunta',
                delete_question_button: 'Eliminar pregunta',
                go_back_question_creation_button: 'Volver',
                confirmation_alert:{
                    add_new_question_button: 'Nueva pregunta',
                    go_section_button: 'Volver a la sección',
                    close_button: 'Cerrar',
                    error_message: 'Ocurrió un problema mientras se añadida la pregunta:', 
                    group_error_message: 'No se puede añadir una pregunta sin grupos objetivos.'
                },

                selection: {
                    title: 'Opciones',
                    active: '¿Activa?',
                    option: 'Opción %{index}',
                    weight: 'Peso %{index}',
                    help: 'Ayuda %{index}',
                    add_option: 'Añadir opción',
                },

                matrix: {
                    row: {
                        title: 'Filas',
                        active: '¿Activa?',
                        option: 'Fila %{index}',
                        weight: 'Peso %{index}',
                        help: 'Ayuda %{index}',
                        add_option: 'Añadir fila',
                    },
                    column: {
                        title: 'Columnas',
                        active: '¿Activa?',
                        option: 'Opción %{index}',
                        weight: 'Peso %{index}',
                        help: 'Ayuda %{index}',
                        add_option: 'Añadir columna',
                    },
                },

                audiovisual: {
                    check_message: '¿Agregar URL de recurso audiovisual?',
                    type: 'Tipo de recurso',
                    url: 'URL del recurso',
                    audio: 'Audio (Soundcloud)',
                    image: 'Imagen (jpeg, jpg, gif, png)',
                    video: 'Video (Youtube)',
                    url_validation_message: 'La URL ingresada no coincide con el formato solicitado. Por favor ingrese un valor válido.',
                    options: {
                        title: 'Opciones',
                        active: '¿Activa?',
                        type: 'Tipo %{index}',
                        url: 'URL %{index}',
                        weight: 'Peso %{index}',
                        add_option: 'Añadir opción',
                    }
                },

                policy: {
                    question: 'Pregunta de aceptación de política',
                    url: 'Dirección de política de datos de la institución',
                    info: 'Una pregunta de tipo Política de Datos siempre estará activa, será obligatoria en el instrumento, y será la primera pregunta que todos los grupos objetivos responderán antes de poder contestar el instrumento. Puede crear múltiples preguntas de política de datos para un mismo instrumento, las cuales serán mostradas al inicio.'
                },

                matrix_multiple: {
                    row: {
                        title: 'Item',
                        active: '¿Activa?',
                        question: 'Pregunta %{index}',
                        help: 'Ayuda',
                        add_row: 'Añadir item',
                        add_option: 'Opción'
                    },
                    item: {
                        active: '¿Activa?',
                        option: 'Opción %{index}',
                        weight: 'Peso %{index}',
                    }           
                },

                reference_matrix: {
                    item: {
                        title: 'Opciones de respuesta',
                        active: '¿Activa?',
                        option: 'Opción %{index}',
                        weight: 'Peso %{index}',
                        help: 'Ayuda %{index}',
                        add_item: 'Añadir item',
                    },
                    question: {
                        title: 'Objetivo',
                        type: 'Tipo Objetivo',
                        year: 'Año',
                        term: 'Periodo'
                    },
                    warning_year_message: 'Advertencia: Tenga en cuenta que al aplicar la pregunta, las subpreguntas serán construidas a partir del objetivo si existe para el año y periodo ingresados. Por favor revise con cuidado los años y periodos disponibles para su selección.',
                },

                conditional: {
                    title: 'Opciones de respuesta (Grupo)',
                    active: '¿Activa?',
                    option: 'Opción %{index}',
                    weight: 'Peso %{index}',
                    help: 'Ayuda %{index}',
                    add_option: 'Añadir opción',
                },

                objective_type_options:{
                    default: 'Seleccione el objetivo',
                    optionA: 'Docentes',
                },
                objective_years_options:{
                    default: 'Seleccione el año',
                },
                objective_terms_options:{
                    default: 'Seleccione el periodo',
                },
            }
        },
        instrument_preview:{
            instrument_menu_preview_button: 'Pre-visualizar',
            instrument_menu_preview_button_hover: 'Vista previa del instrumento',
            title_h: 'Vista previa',
            title: 'Vista previa del instrumento <b>%{instrument}</b>',
            not_available: 'El instrumento no tiene vista previa o no existe',
            next_section: 'Sección siguiente',
            not_available_section: 'No se han creado secciones para este instrumento',
            not_available_question: 'No se han creado preguntas para esa sección',
            groups_select_label: 'Filtrar preguntas por grupo',
            prev_section: 'Sección anterior',
            next_question: 'Pregunta siguiente',
            prev_question: 'Pregunta anterior',
            instrument_start: 'Inicio del instrumento',
            more_info: 'Aquí puedes encontrar mas información relacionado a la pregunta.',
        },
        instrument_apply:{
            title_h: 'Aplicación de instrumento',
            no_token: 'Verifique la url para acceder a la aplicación de un instrumento.',
            not_available: 'El instrumento ya expiro o no se encuentra disponible',
            max_attempts_exceeded: 'Ya has completado esta encuesta. Tus respuestas han sido guardadas.',
            max_attempts_allowed_message: 'Está apunto de realizar un intento para la encuesta.<br/> El número máximo de intentos para resolver la encuesta es <b>%{maxAttempts}</b>. <br/> Actualmente se encuentra en el intento <b>%{currentAttempt}</b>. <br/><br/><b>Nota:</b> Si inicia con un nuevo intento las respuestas se guardarán en un nuevo grupo, y se mostrarán las ultimas respuestas que haya diligenciado por pregunta. Si continua con un intento ya iniciado de la encuesta, recuerde que solo se tendrá por terminado el intento al oprimir el botón <b>Finalizar intento</b>.',
            ok_button: 'Aceptar',
            cancel_button: 'Cancelar',
            more_info: 'Aquí puedes encontrar mas información relacionado a la pregunta.',
            end_attempt: 'Finalizar',
            next_section: 'Sección siguiente',
            prev_section: 'Sección anterior',
            next_question: 'Pregunta siguiente',
            prev_question: 'Pregunta anterior',
            no_section_pending_questions: 'No hay más preguntas pendientes por contestar en esta sección.',
            no_last_section_pending_questions: 'No tiene más preguntas pendientes por contestar.' ,
            dependence_label: 'Depende de: ',

            question_validation: {
                is_required: 'Debe contestar todas las opciones obligatorias de esta pregunta',
                max_length: 'Se ha excedido el numero máximo de caracteres',
                decimals_number: 'El valor del campo debe tener máximo %{decimal} decimales.',
                between: 'El valor del campo debe estar entre %{minValue} y %{maxValue} .',
            },

            data_policy_modal: {
                title: 'Aceptación política de datos',
                body: '¿Acepta las siguientes condiciones de política de datos de su institución para realizar la encuesta?:',
                see_link: 'Ver enlace',
                ok_button: 'Aceptar',
                cancel_button: 'Cancelar',
                warning_message: 'Debe aceptar todos los términos para poder diligenciar el instrumento.'
            },
            message_modal_ok_button: 'Continuar'
        },
        instrument_objective:{
            title_h: 'Configuración de grupos objetivo para un instrumento',
            subtitle: 'Asigne las personas que aplicaran el instrumento dependiendo de su grupo objetivo.',
            add: 'Agregar participante',
            assign: 'Asignar',
            email: 'Correo:',
            advance: 'Avance:',
            users: 'Usuarios',
            close_instrument: 'Instrumento cerrado',
            forwarding: {
                button: 'Reenviar correos',
                button_modal: 'Reenviar',
                title_modal: 'Reenviar correos',
                no_user_message_modal: 'Todos los usuarios ya contestaron el instrumento.'
            },
            tree: {
                add_all: 'Añadir todos',
                started: 'Añadir invitaciones en inicio',
                not_started: 'Añadir invitaciones sin iniciar',
                remove_all: 'Eliminar todos',
            },
            not_sent_participants_notification: {
                title: 'No se ha enviado la invitación para los siguientes correos',
                body: 'No se ha realizado el envío de la invitación para algunos correos. Por favor revise que cada correo se haya escrito correctamente y que no se haya excedido el límite de envío de correos para las configuraciones SMTP. Una vez que haya realizado esta verificación vuelva a hacer la asignación para los siguientes correos:',
                ok_button: 'Aceptar'
            },
            assign_participants_confirmation: {
                title: 'Asignar participantes',
                label: 'Resumen Lista Participantes',
                body: 'Está a punto de realizar la asignación a participantes y envío de correo a los correos añadidos. Se recomienda revisar cada uno de los correos destinados. ¿Desea realizar la asignación de los participantes?',
                cancel_button: 'Volver',
                ok_button: 'Aceptar'
            },
            assign_participants_message: 'Se han asignado las invitaciones para los participantes ingresados',
            forwarding_participants_message: 'Se han reenviado las invitaciones para los participantes seleccionados',
            instrument_without_access_title: 'Sin acceso al instrumento',
            instrument_without_access_message: 'El instrumento al que intenta acceder no está asociado al proceso actual.',
            instrument_without_access_recommendation: 'Por favor, verifique el proceso e instrumento, e inténtelo nuevamente.',
        },
        template_instrument:{
            title: 'Plantillas disponibles',
            subtitle: 'Crear un instrumento a partir de una plantilla existente.',
            template_instrument_select: 'Obtener plantillas por:',
            template_instrument_select_option: {
                optionA: 'Todos',
                optionB: 'Institución',
                optionC: 'Públicos'
            },
            template_table: {
                preview_button: 'Pre-visualizar',
                create_button: 'Usar plantilla'
            },
            cycle_select_options: {
                default: 'Seleccionar ciclo'
            },
            create_from_template_modal: {
                title: 'Crear Instrumento',
                instrument_name_input: 'Nombre del instrumento',
                instrument_cycle: 'Ciclo',
                create_button: 'Crear',
                cancel_button: 'Cancelar'
            }
        },
        instrument_chart:{
            button: 'Mostrar Seguimiento',
            version: {
                label: 'Versión:',
                option: 'Última versión por invitación',
            },
            follow: {
                tab: 'Seguimiento',
                daily: 'Seguimiento Diario de Aplicación de la Encuesta - Finalizadas',
                actor: 'Total de resultados por actor: ',
                title_chart: 'Seguimiento por actor',
                tooltip_unit: 'aplicacion(es)',
            },
            answers: {
                tab: 'Respuestas',
                empty_title: '¡Sin resultados!',
                empty_body: 'Por el momento aún no se encuentran resultados para esta pregunta.',
                title_chart: 'Cantidad de respuestas',
                subtitle_chart: 'Respuestas',
                label_table: 'Respuesta',
                tooltip_unit: 'respuesta(s)',
            },
            export: {
                file_pdf: 'Descargar gráficas',
                file_csv: 'Descargar resultados (CSV)'
            }
        },

        target: {
            campus: 'Sede',
            faculty: 'Facultad',
            code: 'Código',
            label: 'Etiqueta',
            save: 'Guardar',
            cancel: 'Cancelar',
            description: 'Descripción',
        },

        smtp_config: {
            title: 'Configuración de SMTP',
            create: {
                title: 'Nueva configuración de SMTP',
                sub_title: 'Diligencia el siguiente formulario. Los espacios con <span style="color: red">*</span> son obligatorios'
            },
            button_config: 'Configuración Servidor %{index}',
            email: 'Correo electrónico',
            password: 'Contraseña',
            server: 'Servidor',
            port: 'Puerto',
            priority: 'Prioridad',
            size: 'Capacidad',
            ssl: 'SSL',
            tls: 'TLS',
            status: 'Activo',
            save: 'Guardar',
            delete: 'Eliminar',
            add: 'Añadir',
            edit: 'Editar',
            toast: {
                success: '¡La configuración de SMTP fue exitosa!',
                error: '¡Se presentó un error al momento de intentar configurar el SMTP!. Por favor contacte al administrador.'
            }
        },
        improvements_plan:{
            title: 'Plan de mejora',
            subtitle: '',
            code: 'Código',
            code_alt: 'Código institucional',
            follow: 'Seguimiento',
            cycle: 'Ciclo',
            process: 'Proyecto',
            name: 'Nombre',
            budget: 'Presupuesto total',
            budget_desc_no_save: 'Este valor es informativo , en caso de no registrar este valor cuando se asignan presupuestos a las actividades o acciones este se llenará automáticamente con la suma de estos.',
            label: 'Etiqueta',
            detail: 'Detalle o Justificación',
            goal: 'Meta',
            description_goal: "Descripción de la Meta",
            description_campus: 'Descripción de la Sede',
            description_description: 'Descripción de la evidencia',
            description_indicator: 'Descripción del indicador',
            description: 'Evidencia',
            objectiveP: 'Objetivo estratégico',
            aspect: 'Aspectos',
            transversal: 'Transversal',
            institutions: 'Programas e instituciones',
            plan: 'Planes',
            documental: 'Soportes y evidencias',
            statistic: 'Estadísticas',
            perception: 'Percepción',
            indicator: 'Indicadores',
            exogen: 'Exógenos',
            sic: 'Sistema integrado de Calidad',
            send: 'Crear Plan de Mejora',
            update: 'Guardar cambios',
            back: 'Volver',
            target_label_name: 'Objetivo',
            target_label_code: 'SNIES',
            support_label: 'Nombre',
            perception_label: 'Instrumento',
            open_follow: 'Abierto',
            month_follow: 'Mensual',
            two_month_follow: 'Bimestral',
            three_month_follow: 'Trimestral',
            four_month_follow: 'Cuatrimestral',
            six_month_follow: 'Semestral',
            anual_follow: 'Anual',
            bianual_follow: 'Bi-Anual',
            sic_modal: 'Asignación de Sistema integrado de Calidad',
            tab1: 'Información básica',
            tab2: 'Acciones y actividades',
            tab3: 'Servicios externos',
            create_action: 'Crear acción',
            new_action: 'Nueva acción',
            update_action: 'Actualizar Acción',
            execution_short_term: 'Corto plazo',
            execution_medium_term: 'Medio plazo',
            execution_long_term: 'Largo plazo',
            execution_permanent: 'Permanente',
            execution_cyclic: 'Cíclico',
            execution: 'Ejecución',
            start_date: 'Fecha inicial',
            end_date: 'Fecha final',
            no_actions: 'Sin Acciones',
            no_evidence_matrix: 'Hace falta la Matriz de Evidencia',
            no_message_matriz: 'Para continuar con el Plan de Mejora, es necesario implementar la Matriz de evidencia para la delimitación y estructuración de la información que se obtiene.',
            guidance_message: 'Se recomienda que primero se cree la Matriz de Evidencia.',
            success_creation_alert_message: 'Plan de Mejora añadido exitosamente.',
            success_update_alert_message: 'Plan de Mejora actualizado exitosamente',
            success_delete_alert_message: 'Plan de Mejora eliminado exitosamente',
            success_error_alert_message: 'Faltan datos por adicionar',
            action_message: {
                success_creation_alert_message: 'Acción añadida exitosamente.',
                success_update_alert_message: 'Acción actualizada exitosamente',
                success_delete_alert_message: 'Acción eliminada exitosamente',
            },
            action: 'Acción',
            edit_action: 'Editar acción',
            action_name: 'Nombre de la acción de mejora',
            create_activity: 'Crear actividad',
            action_filter: 'Busca nombre de acción o códigos',
            no_activities: 'Sin actividades',
            new_activity: 'Nueva actividad',
            matrix_level: 'Nivel de la matriz',
            circle_question: 'Indique el nivel de la matriz al cual va a crear la acciones.',
            no_recomendation: 'Sin recomendaciones',
            action_recomendation: 'Acciones recomendadas',
            create: 'Crear acción hacia',
            strength: 'la fortaleza de ',
            oportunity: 'la oportunidad de mejora de ',
            weakness: 'la debilidad de ',
            matrix: 'Matriz',
            objective: 'Grupo objetivo',
            origin: '¿Por qué?',
            type: 'Tipo de Acción',
            activity_title: 'Actividad',
            edit_activity: 'Editar actividad',
            expected_result: 'Resultado esperado',
            select_type_origin: 'Seleccione el origen de datos',
            type_origin: 'Origen de datos',
            internal_origin: 'Origen interno',
            external_origin: 'Origen externo',
            internal_origin_desc: 'Se usara los datos del resultado del proceso del sistema PREST',
            external_origin_desc: 'Se usara los datos por medio de los webservices suministrados',
            accept: 'Aceptar',
            notes: 'Notas de evaluador',
            external_col: 'Responsables',
            under_external_col: 'Escriba el nombre y cargo',
            level_col: 'Nivel responsabilidad',
            under_level_col: 'Indique si es Institucional, facultad o programa',
            message_phva: 'La acción le hace falta el tipo de actividad: ',
            messages_phva: 'La acción le hacen falta los tipos de actividad: ',
            activity:{
                title: 'Actividades',
                subtitle: '',
                code: 'Código',
                code_alt: 'Código institución',
                action: 'Acción o grupo',
                plan: 'Plan de mejora',
                campus: 'Sede',
                what:'¿Qué? <|> What?',
                name: 'Nombre de actividad',
                when: '¿Cuándo? <|> When?',
                start: 'Inicio',
                end: 'Fin',
                other_origin: 'Otros grupos objetivo',
                where: '¿Dónde? <|> Where?',
                targets: 'Sectores Impactados (programas, facultades, sedes, institución)',
                groups: 'Impacto',
                matrix: 'Matriz de evidencias',
                who: '¿Quién? <|> Who?',
                collaborator: 'Colaboradores',
                how_much: '¿Cuánto? <|> How Much?',
                resources: 'Recursos',
                how: '¿Cómo? <|> How?',
                action_type: 'Tipo actividad',
                auto_indicator: 'Indicador',
                type_indicator: 'Tipo de Indicador',
                indicator_option: {
                    move: 'Gestión',
                    impact: 'Impacto',
                    result: 'Resultados'
                },
                at_plan:'(P) Planear',
                at_do: '(H) Hacer',
                at_verify: '(V) Verificar',
                at_act: '(A) Actuar',
                result: 'Resultado',
                result_kpi: 'KPI',
                result_quantity: 'Presupuesto',
                result_document: 'Documento o enlace',
                result_other: 'Otro',
                indicator_move: 'Gestion',
                indicator_impact: 'Impacto',
                indicator_result: 'Resultado',
                indicator_arch: 'Logro',
                why: '¿Por qué? <|> Why?',
                plans: 'Planes',
                quantity: 'Cifras',
                sic: 'SIC',
                indicator: 'Indicadores documentales',
                notes: 'Notas de evaluador',
                perception: 'Percepción',
                exogen: 'Fuentes Exógenas',
                other: 'Otros',
                tags: 'Tags',
                add: 'Agregar actividad',
                update: 'Actualizar actividad',
                delete: 'Eliminar actividad',
                tags_placeholder: 'Ingrese los elementos y sepárelos por coma',
                colab: 'Colaborador',
                rol: 'Rol',
                add_colab: 'Añadir Colaborador',
                update_colab: 'Editar Colaborador',
                r_type: 'Tipo recurso',
                r_detail: 'Detalle recurso',
                r_value: 'Valor unidad',
                r_scale_value: 'Escala valor',
                r_quantity: 'Cantidad',
                r_scale_quantity: 'Escala cantidad',
                r_scale: 'No. Decimales',
                r_origin:  'Origen',
                r_url: 'URL',
                add_resource: 'Añadir Recurso',
                update_resource: 'Actualizar Recurso',
                remove: 'Quitar',
                escape: 'Volver',
                follow_type: 'Tipo de seguimiento',
                url_follow: 'URL de seguimiento externo',
                internal_follow: 'Seguimiento Interno',
                external_follow: 'Seguimiento Externo',
                create: {
                    success: 'Actividad creada',
                    error: 'Error al crear la actividad'
                },
                edit: {
                    success: 'Actividad editada',
                    error: 'Error al editar la actividad'
                },
            },
            file_load:{
                load_action: 'Cargar de acciones',
                load_action_file: 'Cargar archivo de acciones',
                load_activity: 'Cargar de actividades',
                load_activity_file: 'Cargar archivo de actividades',
                advanced: 'Opciones avanzadas',
                related_activity: 'Crear actividad por defecto',
                download_format_activity: 'Descargar formato carga de actividades',
                download_format_actions: 'Descargar formato carga de acciones',
                format_alert: '¡Alerta! Por favor, evita modificar los encabezados del formato, ya que esto puede comprometer la integridad de los datos. Si tienes alguna pregunta, no dudes en contactar a un administrador para obtener asistencia.'
            }
        },

        review: {
            title: 'Evaluación final',
            sub_title: 'Por niveles de estándar',
            no_result: '¡Sin resultado!',

            popover_info: {
                average: 'Promedio',
                weight: 'Peso'
            },

            weighing: {
                see: 'Ver ponderación',
                title: 'Ponderación'
            },

            assessment: {
                title: 'Percepción',
                sub_title: 'Promedio por estamento',
                no_info: 'No se han aplicado instrumentos o no se han registrado resultados cuantificables.',
                no_groups_data_title: '¡Sin resultado por estamento!',
                no_groups_data_sub_title: 'El nivel actual no fue asignado en los instrumentos de percepción para mostrar resultados por estamento.'
            },

            evidence: {
                title: 'Documental',
                sub_title: 'Cantidad de documentos valorados por tipo',
                no_info: 'No se han valorado documentos.',
                no_document_data_title: '¡Sin resultado por tipo de documento!',
                no_document_data_sub_title: 'El nivel actual no tiene documentos asignados para mostrar resultados por tipo.'
            },

            statistical_evidence: {
                title: 'Estadístico',
                sub_title: 'Cantidad de documentos estadísticos valorados por tipo',
                no_info: 'No se han valorado documentos.',
                no_document_data_title: '¡Sin resultado por tipo de documento!',
                no_document_data_sub_title: 'El nivel actual no tiene documentos asignados para mostrar resultados por tipo.'
            },

            supports: {
                title: 'Documentos',
                sub_title: 'Lista de documentos valorados',
            },

            improvement_plan: {
                title: 'Recomendaciones para el plan de mejora',
                button: 'Ver OF'
            }
        },
        teacher_review:{
            title:'Evaluación Docente',
            no_result: '¡Sin resultado!',
            config:'Configuración de evaluación docente',
            accept: 'Aceptar',
            applicant: 'aplicación para el instrumento: ',
            instrument: 'Seleccione un instrumento',
            version: 'Seleccione una aplicación del instrumento',
            add: 'Agregar',
            remove: 'Eliminar',
            percentage: 'Porcentaje en la evaluación',
            percentage_remaining: 'máximo disponible',
            open_config: 'Abrir configuración',
            close: 'Cerrar',
            no_more_100: 'No puede tener mas de 100% en los porcentajes de evaluación'
        },
        add_standard:{
            title: '<b>Crear Nuevo Estandar</b>',
            subtitle: 'Creación de un nuevo estándar para el contrato',
            standard_name: 'Nombre estándar',
            standard_description: 'Descripción estándar',
            standard_min_target: 'Nivel Target Mínimo',
            standard_max_target: 'Nivel Target Máximo',
            standard_type: 'Tipo Estándar',
            copy_matrix_standard:{
                header: 'Configuración Matriz',
                copy_label: 'Copiar Matriz',
                default_option: 'Seleccionar estándar'
            },
            copy_timeline_standard:{
                header: 'Configuración Cronograma',
                copy_label: 'Copiar Cronograma',
            },
            custom_validation_messages:{
                not_valid_total_formula: 'La suma de los valores de la fórmula debe ser igual a 100.',
                not_valid_target_levels: 'Se debe seleccionar un Nivel Target Máximo mayor al Nivel Target Mínimo.',
            },
            success_creation_alert_message: 'Se ha creado el estándar exitosamente.',
            error_creation_alert_message: 'Ocurrió un problema mientras se creaba el estándar.',
            save_button: 'Guardar',
            back_button: 'Volver'
        },
        contract: {
            section_one: {
                tab: 'Configuración y personalización de la plataforma',
                title: 'Logos y página de inicio',
                logo_url: 'Logotipo principal',
                logo_alt: 'Logotipo lateral',
                color_code: 'Color Institucional',
                icon_lateral: 'Icono lateral visible',
                url_anexa: 'URL de redirección icono lateral',
                message: 'Texto en página de inicio',
                alert_title_save: 'Guardar cambios de personalización',
                alert_message_save: 'Advertencia: Al guardar los cambios de personalización, se modificarán las opciones previas de su institución. ¿Está seguro de que desea continuar?',
            },
            section_two: {
                tab: 'Actualizar política de datos',
                title: 'Política de datos',
                policy_url: 'URL política de tratamiento de datos Institucional',
                alert_title_save: 'Actualizar política de tratamiento de datos Institucional',
                alert_message_save: '¿Desea actualizar la política de tratamiento de datos? Este cambio implicará que todos los usuarios deban aceptarla de nuevo.',
            },
            section_three: {
                tab: 'Personalización de los estándares',
                title: 'Estándares personalizados',
                standard: 'Estándar',
                status: 'Estado',
                name: 'Nombre del estándar',
                description: 'Descripción del estándar',
                appreciation: 'Apreciación',
                statistics: 'Estadístico',
                evidence: 'Documental',
                modal_reports: {
                    title: 'Vincular reportes al estándar'
                },
                system_reports: 'Reportes del sistema',
                report_standard: 'Reportes para el estándar',
                groups_appreciation: 'Públicos Objetivos Instrumentos',
                groups_evidence: 'Grupos especializados matriz',
                empty: 'No se encontraron estándares personalizados',
                add_reports: 'Vincular reportes',
                alert_title_save: 'Actualizar estándar',
                alert_message_save: 'Advertencia: Al guardar los cambios de personalización del estándar se modificarán las opciones previas. ¿Está seguro de que desea continuar?',
                objectives_cards: {
                    name: 'Grupos objetivos',
                    inputs: {
                        input_name:{
                            on_edit_label: 'Nombre del grupo objetivo',
                            to_show_label: 'Grupo objetivo',
                        },
                        input_description:{
                            on_edit_label: 'Descripción',
                            to_show_label: '',
                        },
                    },
                    no_objectives: 'No hay objetivos'
                },
                buttons: {
                    scheduler_template_button: 'Consultar y editar plantilla cronograma',
                    matrix_template_button: 'Consultar y editar Plantilla Matriz',
                    rubrics_button: 'Consultar y editar rubricas de valoración'
                }
            },
            util: {
                message_placeholder: 'Ingrese el mensaje que aparecerá en la página de inicio y la contraportada de los reportes',
                save: 'Guardar cambios',
                confirm: 'Confirmar',
                cancel: 'Cancelar',
                add_standard: 'Crear estándar',
                open_view: 'Abrir vista previa'
            }
        },
        rubrics: {
            rubric_list:{
                title: 'Rúbricas',
                subtitle: 'Lista de rúbricas por estándar',
                table:{
                    code_col: 'Código',
                    rubric_col: 'Rúbrica',
                    type_col: 'Tipo pregunta'
                },
                add_buton: 'Añadir rúbrica',
                no_edit_allowed: {
                    alert_header: 'Sin acceso a rúbricas',
                    alert_message: 'No es posible visualizar las rúbricas del estándar seleccionado.'
                }
            },
            rubric_form:{
                add_title: 'Añadir rúbrica',
                edit_title: 'Editar rúbrica',
                rubric_name: 'Nombre Rúbrica',
                help: 'Ayuda',
                status: 'Estado',
                code: 'Código',
                detail: 'Detalle',
                question_type: 'Tipo de pregunta',
                save_button: 'Guardar',
                return_button: 'Volver',
                status_options: {
                    option_A: 'Seleccione el estado',
                    option_B: 'Activo',
                    option_C: 'Inactivo'
                },
                type_default_option: 'Seleccione el tipo',
                change_type_modal: {
                    title: 'Cambiar tipo de pregunta',
                    warning_message: 'Actualmente la pregunta tiene asignado un tipo y opciones de respuesta. Si cambia el tipo de pregunta las opciones serán eliminadas al guardar la pregunta. ¿Desea cambiar el tipo de pregunta?',
                    accept_change_button: 'Aceptar',
                    cancel_button: 'Cancelar'
                },
                max_length_message_feedback: 'Se ha excedido el número máximo de elementos en el texto',
                required_all_fields_message: 'Aún existen campos obligatorios sin rellenar'
            }
        },
        dashboard:{
            title: 'Dashboard general de procesos',
            subtitle: 'Aquí podrá ver el dashboard general de control de todos los procesos de su institución.',
        },

        reports: {
            title: 'Reportes',
            subtitle: 'Generar reportes del proceso de autoevaluación para el ciclo actual.',
            success: 'Se envió la solicitud para generar el reporte.',
            filters: 'Filtros',
            iteration: 'Ciclo',
            year: {
                name: 'Año',
                message: 'Diligencie el año desde el cual se desea generar la información:',
            },
            period: {
                name: 'Periodo',
                message: 'Seleccione el periodo desde el cual se desea generar la información:',
            },

            weighing_card: {
                header: 'Ponderación'
            },

            documental_card: {
                header: 'Documentos'
            },

            format_modal: {
                title: 'Formato',
                message: 'Por favor elegir el formato para realizar la solicitud de generación del reporte.',

                excel: 'EXCEL',
                pdf: 'PDF',
                all: 'TODOS LOS FORMATOS',
                word: 'WORD'
            },

            fields: {
                request_date: 'Fecha de la petición',
                format: 'Formato',
                label: 'Reporte',
                buttons: 'Acciones'
            },

            warning_message: 'No hay reportes asociados al estándar del proceso de autoevaluación actual.'
        },

        templates: {
            title: 'Plantillas de información institucional',
            subtitle: 'Información de la institución alojada en las plantillas que se dirigen al ministerio.',

            new: {
                button: 'Crear plantilla',

                modal: {
                    title: 'Añadir una nueva plantilla',
                },
                name: 'Nombre',
                description: 'Descripción',

                card: {
                    title: 'Campos',

                    fields: {
                        name: 'Nombre',
                        description: 'Descripción'
                    }
                }
            }
        },
        templates_eva: {
            title: 'Plantillas evaluación docente',
            subtitle: 'Información de la institución alojada para la evaluación docente y cargue de información institucional de estudiantes, docentes, cursos y recursos.',

            new: {
                button: 'Crear plantilla',

                modal: {
                    title: 'Añadir una nueva plantilla',
                },
                name: 'Nombre',
                description: 'Descripción',

                card: {
                    title: 'Campos',

                    fields: {
                        name: 'Nombre',
                        description: 'Descripción'
                    }
                }
            }
        },
        notes:{
            title_h:'Notas',
            subtitle: 'Aquí encontrara las notas de evaluación para este proyecto: &data.',
            add: 'Agregar Nota',
            create_conclusion: 'Crear nota de conclusión',
            see_conclusion: 'Ver nota de conclusión',
            no_notes: 'Sin Notas',
            meets: '¿Cumple?',
            yes: 'Si',
            no: 'No',
            observation_type: 'Tipo de observación',
            type_one: 'Reconocimiento',
            type_two: 'No conformidad',
            type_three: 'Observación',
            type_four: 'Nota Cerrada',
            type_five: 'Nota de conclusión',
            objective: 'Objetivo',
            matrix: 'Matriz',
            note: 'Nota',
            notes: 'Notas',
            creation_date: 'Fecha de creación',
            term_date: 'Fecha de plazo',
            actions: 'Acciones',
            new_note: 'Nueva nota',
            see_note: 'Ver Detalle',
            edit_note: 'Editar nota',
            close_note: 'Cerrar nota',
            save: 'Guardar',
            term: 'Plazo',
            days: 'Días',
            weeks: 'Semanas',
            months: 'Meses',
            years: 'Años',
            semesters: 'Semestres',
            weighing: 'Ponderación',
            perception: 'Percepción',
            valoration: 'Documental',
            improvement: 'Plan de mejora',
            process: 'Todo el Proyecto',
            see_notes: 'Ver notas',
            title: 'Titulo',
            level: 'Nivel de la nota',
            high_level: 'Mayor',
            low_level: 'Menor',
            activities_pm: 'Actividades del plan de mejora relacionadas',
            no_activities_pm: 'Sin actividades del plan de mejora',
            detail: 'Detalle',
            ok: 'Aceptar',
            version: 'Versión',
            versions: 'Versiones',
            created: 'La nota ha sido creada satisfactoriamente',
            updated: 'La nota se ha actualizado satisfactoriamente',
            closed: 'La nota ha sido cerrada satisfactoriamente',
            user: 'Evaluador',
            my:{
                title: 'Mis notas',
                subtitle: 'Aquí encontrara todas sus notas en todos los proyectos que evalúe.'
            }
        },
        participants:{
            load_participants: 'Cargar desde participantes',
            participants: 'Participantes',
            cancel: 'Cancelar',
            ok: 'Cargar',
            select_type: 'Selecciona un grupo de participantes a cargar',
            success: 'Se cargaran &x participantes.',
            warning: 'Se encontraron &x participantes, sin embargo los datos de los participantes no están actualizados a este año y periodo, ¿quiere continuar con la carga de participantes?',
            danger: 'No se encontraron participantes registrados en el grupo seleccionado.',
            
        }
    }
}

export default messages